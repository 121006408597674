"use client";

import { TaskStatus } from "@palette.tools/model";
import { DatetimePopover } from "@palette.tools/react";
import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import ImageFallback from "../image/ImageFallback";
import { DeadlineLabel } from "../labels";
import { StatusDropdownMenu } from "../menus";
import { cn } from "../shadcn/lib/utils";
import EditableTable from "./EditableTable";
import { TableHeaderCell } from "./shared";
import { Asset, Project, Task, UserProfile, Workspace, getPermissions, transact, useAuth } from "@palette.tools/model.client";


interface TaskRow {
  id: string,
  name: string,
  assignee: string | undefined,
  deadline: number | undefined,
  status: TaskStatus | undefined,
  editable: boolean,
}

function convertTaskToRow(task: Task, editable: boolean): TaskRow {

  return {
    id: task.id,
    name: task.data.name || "",
    assignee: task.roles.assignee[0],
    deadline: task.data.deadline,
    status: task.data.status,
    editable,
  }
}

async function convertTasksToRows(
  tasks?: Task[],
  profile?: UserProfile,
  projectsByTask?: Record<string, Project>,
  workspacesByTask?: Record<string, Workspace>
): Promise<TaskRow[]> {
  if (!tasks) return [];
  return tasks.map(task => {
    const permissionsProps = {
      profile,
      project: projectsByTask?.[task.id],
      workspace: workspacesByTask?.[task.id],
      task: task,
    };
    return convertTaskToRow(task, getPermissions(permissionsProps).canEditTask);
  });
}


function convertRowToTask(tasks: Task[] | undefined, row: Row<TaskRow>) {
  return (tasks || []).find(x => x.id === row.original.id);
}


const AssetCell: React.FC<{asset?: Asset}> = ({ asset }) => {
  return <div className="min-w-[150px] max-w-[300px] flex flex-row gap-x-2">
    <ImageFallback
      fallback="none"
      src={asset?.data.thumbnail_url}
      width={30}
      height={20}
      className="rounded-md border-gray-300 bg-gray-500"
      alt={`Thumbnail for ${asset?.data.name || "unknown item"}`}
    />
    <p className="max-w-full line-clamp-2 break-all">
      {asset?.data.name}
    </p>
  </div>
}


const NameCell: React.FC<{name?: string}> = ({name}) => {
  return <div className="min-w-[150px] max-w-[300px]">
    <p className="max-w-full line-clamp-2 break-all">{name}</p>
  </div>
}

const DeadlineCell: React.FC<{deadline?: number, isComplete?: boolean, editable?: boolean}> = ({
  deadline,
  isComplete,
  editable,
}) => {

  return <div className="flex flex-col content-center items-center float-left ml-4">
    <div onClick={(e) => {
      if (!editable) return;
      e.preventDefault(); e.stopPropagation();
    }}>

      <DatetimePopover disable={!editable} selectedDatetime={deadline ? new Date(deadline) : undefined}>
        <DeadlineLabel
          timestamp={deadline}
          editable={editable}
          isComplete={isComplete}
        />
      </DatetimePopover>

    </div>
  </div>
}


const StatusCell: React.FC<{
  status?: number,
  editable?: boolean,
  onSelectStatus?: (status: number) => void,
}> = ({
  status,
  editable,
  onSelectStatus,
}) => {

  return <div className="flex flex-col content-center items-center float-left">
    <div onClick={(e) => {
      if (!editable) return;
      e.preventDefault(); e.stopPropagation();
    }}>
      <StatusDropdownMenu
        editable={editable}
        selectedStatus={status}
        onSelectStatus={onSelectStatus}
      />
    </div>
  </div>
}

interface MyTasksTableProps {
  tasks: Task[];
  assetsByTask: Record<string, Asset>;
  projectsByTask: Record<string, Project>;
  workspacesByTask: Record<string, Workspace>;
  onSelectTasks?: (selectedTasks: Task[]) => void;
  className?: string;
}

export interface MyTasksTableHandle {
  clearSelection: () => void;
}

export const MyTasksTable = forwardRef<MyTasksTableHandle, MyTasksTableProps>(({
  tasks,
  assetsByTask,
  projectsByTask,
  workspacesByTask,
  onSelectTasks,
  className,
}, ref) => {

  const { profile } = useAuth();

  // Convert user profiles to row model.
  const [isConverting, setConverting] = React.useState(true);
  const [localData, setLocalData] = React.useState<TaskRow[]>([]);

  // Table states
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  // Expose clearSelection method to parent
  useImperativeHandle(ref, () => ({
    clearSelection: () => {
      setRowSelection({});
    },
  }));

  const columns: ColumnDef<TaskRow>[] = useMemo(() => [

    {
      accessorKey: "item",
      header: ({ column }) => <TableHeaderCell column={column} text="Item" />,
      cell: ({ row }) => <AssetCell asset={assetsByTask[row.original.id]} />,
      sortingFn: (a, b) => {
        const assetA = assetsByTask[a.original.id];
        const assetB = assetsByTask[b.original.id];
        return (assetA?.data.name || "").localeCompare(assetB?.data.name || "");
      }
    },

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="Task"/>,
      cell: ({ row }) => <NameCell name={row.original.name} />,
    },

    {
      accessorKey: "deadline",
      header: ({ column }) => <TableHeaderCell column={column} text="Deadline"/>,
      sortingFn: "datetime",
      cell: ({ row }) =>
        <DeadlineCell
          deadline={row.original.deadline}
          isComplete={row.original.status === TaskStatus.Complete}
          editable={row.original.editable}
        />,
    },

    {
      accessorKey: "status",
      header: ({ column }) => <TableHeaderCell column={column} text="Status"/>,
      cell: ({ row }) => <StatusCell
        status={row.original.status}
        editable={row.original.editable}
        onSelectStatus={(status) => {
          const task = convertRowToTask(tasks, row);
          if (!task || task.data.status === status) return;
          transact(task.update({ status }));
        }}
      />
    },

  ], [tasks, assetsByTask]);

  useEffect(() => {
    if (!tasks) setLocalData([]);
    setConverting(true);
    async function _convertTasksToRows() {
      const rows = await convertTasksToRows(tasks, profile, projectsByTask, workspacesByTask);
      setLocalData(rows);
    }
    _convertTasksToRows().finally(() => setConverting(false));
  }, [profile, tasks, projectsByTask, workspacesByTask]);

  useEffect(() => {
    if (!table) return;
    const selectedTasks: Task[] = table
      .getSelectedRowModel()
      .rows
      .map(x => tasks ? tasks[x.index] : undefined)
      .filter(x => !!x) as Task[];
    onSelectTasks && onSelectTasks(selectedTasks);
  }, [rowSelection]);

  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return <EditableTable
    containerClassName="flex-1 min-h-0 h-full"
    rowClassName="group h-min"
    cellClassName="px-2 py-4 align-middle"
    enableOrdering={false}
    selectionMode={"single"}
    table={table}
    getUuid={(row) => row.original.id}
  />

});
