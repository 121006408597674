import React from "react";


function compareKeys(key1: any[], key2: any[]) {
  return key1.length === key2.length && key1.every((value, index) => value === key2[index]);
}

export const hasAttribute = (target: EventTarget | null, attribute: string): boolean => {
  if (!target || !('getAttribute' in target)) return false;
  let element: HTMLElement | null = target as HTMLElement;
  return element.hasAttribute(attribute);
};

type KeyGenerator<T extends (...args: any[]) => any> = (...args: Parameters<T>) => any[];

export function useMemoizedCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: React.DependencyList,
  keyGenerator: KeyGenerator<T>
): T {
  const cacheRef = React.useRef<{
    key: any[];
    result: ReturnType<T>;
  } | null>(null);

  const memoizedCallback = React.useCallback(callback, deps);

  return React.useCallback(
    ((...args: Parameters<T>): ReturnType<T> => {
      const key = keyGenerator(...args);

      if (cacheRef.current && compareKeys(cacheRef.current.key, key)) {
        return cacheRef.current.result;
      }

      const result = memoizedCallback(...args);
      cacheRef.current = { key, result };
      return result;
    }) as T,
    [memoizedCallback, keyGenerator]
  );
}

// Helper function to compare arrays

export const arraysEqual = (a: readonly string[] | null, b: readonly string[] | null) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};
