"use client";

import React from "react";
import { Project, UserProfile, Workspace, transact, usePermissions } from "@palette.tools/model.client";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";
import { UserPopover } from "..";


export const useAddUsers = (
  workspace: Workspace | null,
  project: Project | null,
) => {
  const { canManageProjectUsers: canAdd } = usePermissions({ workspace, project });
  const [error, setError] = React.useState<string>("");
  const [isAdding, setAdding] = React.useState<boolean>(false);

  const add = async (
    _profile: UserProfile,
  ) => {
    if (!workspace || !project || !canAdd) return;

    flushSync(() => {
      setError("");
      setAdding(true);
    })
    await transact([
      ...project.link("profile", _profile.id),
    ])
    .catch((e) => setError(e.message))
    .finally(() => {
      setAdding(false);
    });
  };

  return {
    canAdd,
    error,
    isAdding,
    add,
  };
};

interface AddUserTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  workspace: Workspace | null;
  project: Project | null;
  workspaceUsers?: UserProfile[];
  projectUsers?: UserProfile[];
  disabled?: boolean;
}

export const AddUserTrigger = <T extends React.ElementType>({
  children,
  workspace,
  project,
  workspaceUsers = workspace?.links.profile || [],
  projectUsers = project?.links.profile || [],
  disabled,
}: AddUserTriggerProps<T>) => {

  const { canAdd, add } = useAddUsers(workspace, project);

  const selectableUsers = workspaceUsers.filter(x => !projectUsers.find(y => y.id === x.id));

  if (disabled || !canAdd) return (
    <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent className="italic text-muted-foreground">Can't add users!</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <UserPopover selectableUsers={selectableUsers} onSelectUser={add}>
      {children}
    </UserPopover>
  );

};
