"use client";

import React, { useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";


export const DeletePhaseModal: React.FC<{
  open: boolean,
  onClose: () => void,
  disabled?: boolean,
  onDelete?: () => Promise<void>,
}> = ({
  open,
  onClose,
  disabled,
  onDelete,
}) => {

  // Regular hooks
  const [isDeleting, setDeleting] = useState(false);

  const submitDisabled = (
    disabled
  );

  return <Modal
    open={open}
    header={disabled ? "Cannot Delete Phase" : "Delete Phase?"}
    description={disabled ? "You are not allowed to delete this phase." : "All tasks will be moved to the Task Board."}
    primaryButtonClass="bg-destructive hover:bg-destructive/50 text-destructive-foreground"
    primaryButtonContent={isDeleting ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Deleting"}</> : "Delete"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setDeleting(true)
        await onDelete?.()
          .finally(() => {
            setDeleting(false);
            onClose && onClose();
          });
      }
    }}
  />

}
