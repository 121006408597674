"use client";

import { delete_file_entry } from "@palette.tools/api.client";
import { abbreviateName, formatStorage } from "@palette.tools/model";
import { Asset, Category, Comment, FileEntry, Project, Task, Workspace, downloadFile, transact, usePermissions } from "@palette.tools/model.client";
import { LoadingIcon } from "@palette.tools/react.icons";
import { formatFullTimestamp } from "@palette.tools/utils";
import { FileDownIcon, Trash2Icon, UploadCloudIcon } from "lucide-react";
import React, { useState } from "react";
import { CommentBox } from "../editors/CommentBox";
import { LinkBox } from "../editors/LinkBox";
import { Button } from "../shadcn/components/ui/button";
import { Separator } from "../shadcn/components/ui/separator";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { cn } from "../shadcn/lib/utils";
import { FileUploadTrigger } from "../triggers/FileUploadTrigger";
import { TypographyH4, TypographyH5, TypographyMedium, TypographyMuted } from "../typography";
import { Match, SwitchCase } from "../utils";


const FileEntriesList: React.FC<{
  workspace: Workspace,
  file_entries: FileEntry[],
  onClickFileEntry?: (file_entry: FileEntry) => void,
}> = ({
  workspace,
  file_entries,
  onClickFileEntry,
}) => {

  const [isDeleting, setIsDeleting] = useState<Record<string, boolean>>({});

  return <TooltipProvider skipDelayDuration={0} delayDuration={0}><div className="flex flex-col gap-y-1">
    {
      file_entries.map((file_entry, i) => {

        const listItem = <div className="flex flex-row w-full items-center cursor-pointer"
          key={i}
        >

          <a href={file_entry.data.url || ""} target="_blank">
          <FileDownIcon
            className="stroke-muted-foreground cursor-pointer hover:stroke-foreground"
            width={16}
            height={16}
            onClick={() => downloadFile(file_entry)}
          />
          </a>

          <div className="w-[8px]" />

          <TypographyMedium
            className="text-muted-foreground group-hover:text-foreground cursor-pointer"
            onClick={() => onClickFileEntry?.(file_entry)}
          >
            {abbreviateName(file_entry.data.name || "", 40)}
          </TypographyMedium>

          <div className="flex-1" />

          <SwitchCase>
            <Match when={!isDeleting[file_entry.id]}>
              <Trash2Icon width={16} height={16} className="stroke-destructive/50 hover:stroke-destructive cursor-pointer"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsDeleting((prev) => { return {...prev, [file_entry.id]: true } });

                  await delete_file_entry(file_entry.id, workspace.id)
                    .catch((e) => alert(e))
                    .finally(() => {
                      setIsDeleting((prev) => { return {...prev, [file_entry.id]: false } });
                    });

                }}
              />
            </Match>
            <Match when={isDeleting[file_entry.id]}>
              <div className="w-[16px] h-[16px]"><LoadingIcon /></div>
            </Match>
          </SwitchCase>

          <div className="w-[16px]" />

        </div>

      return <Tooltip>
        <TooltipContent>
          {file_entry.data.created_at ? <TypographyMuted className="italic">{formatFullTimestamp(file_entry.data.created_at)}</TypographyMuted> : null}
          <br />
          <TypographyMedium className="text-foreground text-bold">
            {formatStorage(file_entry.data.size || 0)}
          </TypographyMedium>
        </TooltipContent>
        <TooltipTrigger>{listItem}</TooltipTrigger>
      </Tooltip>
      })
    }
  </div></TooltipProvider>

}


export const TaskPanel: React.FC<{
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  task: Task | null,
  file_entries: FileEntry[],
  comments: Comment[],
  className?: string,
  onClickFileEntry?: (file_entry: FileEntry) => void,
  onClickTaskTitle?: (task: Task) => void,
}> = ({
  workspace,
  project,
  category,
  asset,
  task,
  file_entries,
  comments,
  className,
  onClickFileEntry,
  onClickTaskTitle,
}) => {

  const { canEditTask } = usePermissions({ workspace, project, category, asset });
  const [isUploading, setUploading] = React.useState(false);
  const canUpload = !isUploading
    && !!workspace
    && !!task
    && workspace.data.storage_max_file_size !== undefined
    && workspace.data.storage_max_file_size !== null
    && workspace.data.storage_limit !== undefined
    && workspace.data.storage_limit !== null
    && workspace.data.storage_size !== undefined
    && workspace.data.storage_size !== null
    && workspace.data.storage_size < workspace.data.storage_limit;

  return <div className={cn("flex flex-col h-full w-full rounded-md border-[1px] border-border p-2 gap-y-1", className)}>
    <TypographyH4
      onClick={() => task && onClickTaskTitle?.(task)}
      className={cn(
        // Base style
        "ml-3 mt-1 text-muted-foreground",
        // Clickable link
        task && onClickTaskTitle ? "hover:text-foreground cursor-pointer" : "",
      )}
    >
      {!!task ? (task.data.name || "Untitled task") : "Select a task"}
    </TypographyH4>
    <div className="min-h-2" />
    <Separator />

    {task &&
      <>
        {task.data.shortcut_url || canEditTask ? <>
            <LinkBox
              editable={!!task && canEditTask}
              initialUrl={(task?.data.shortcut_url) || ""}
              onSave={(shortcut_url: string) => task && task.update({ shortcut_url })}
            />
            <Separator />
          </>
          : <></>
        }

        <div className="min-h-2" />

        <div className="flex flex-row">
          <TypographyH5 className="ml-3 mt-1 text-muted-foreground">Files</TypographyH5>
          <div className="flex-1" />
          <FileUploadTrigger
            disabled={!canUpload}
            workspace={workspace}
            project={project}
            onStart={() => setUploading(true)}
            onCreateEntry={async (file_entry) => await transact(task.link("file_entry", file_entry)) }
            onFinish={() => setUploading(false)}
          >
            <Button type="button" disabled={!canUpload}>
              {isUploading
                ? <div className="w-[16px] h-[16px]"><LoadingIcon /></div>
                : <UploadCloudIcon className="w-[16px] h-[16px]" />
              }{"  Upload"}
            </Button>
          </FileUploadTrigger>
        </div>
        <Separator />

        {file_entries && workspace ? <div className="min-h-[50px] max-h-[200px] overflow-y-auto">
          <FileEntriesList
            workspace={workspace}
            file_entries={file_entries}
            onClickFileEntry={onClickFileEntry}
          />
        </div> : null}

        <CommentBox
          comments={comments}
          users={workspace?.links.profile || []}
          workspace={workspace}
          project={project}
          parent={task}
        />
      </>
    }

  </div>

}
