"use client";

import React, { useEffect } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { ArrowUpDownIcon, LogOutIcon, MoreHorizontalIcon, Trash2Icon } from "lucide-react";

import {
    Button, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, Show, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, UserPopover, cn
} from "@palette.tools/react";

import { remove_project_user } from "@palette.tools/api.client";
import { ProjectRoles, getProfileName } from "@palette.tools/model";
import { Project, UserProfile, Workspace, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { getProjectRole } from "@palette.tools/model.client/src/project";
import { filterUndefined } from "@palette.tools/utils";
import { ProjectRolesDropdownMenu } from "../menus";
import EditableTable from "./EditableTable";
import { TableHeaderCell } from "./shared";


interface UserProfileRow {
  id: string,
  thumbnailStorageURL: string,
  name: string,
  email: string,
  role?: number,
}

function convertUserProfileToRow(project: Project, profile: UserProfile): UserProfileRow {
  return {
    id: profile.id,
    thumbnailStorageURL: profile?.data.image_url || "",
    name: getProfileName(profile),
    email: profile.data.email || "",
    role: getProjectRole(project, profile),
  }
}

function convertUserProfilesToRows(project?: Project | null, profiles?: UserProfile[]): UserProfileRow[] {
  let rows: UserProfileRow[] = [];
  if (!project || !profiles) {
    return rows;
  }
  for (let profile of profiles) {
    rows.push(convertUserProfileToRow(project, profile));
  }
  return rows;
}


export const ProjectUserTable: React.FC<{
  profiles: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  className?: string,
}> = ({
  profiles,
  workspace,
  project,
  className = "max-w-[920px] min-w-[770px]",
}) => {

  const { profile } = useAuth();
  const { canEditProject } = usePermissions({ workspace, project });

  // Convert user profiles to row model.
  const [localData, setLocalData] = React.useState<UserProfileRow[]>(convertUserProfilesToRows(project, profiles));
  useEffect(() => {
    if (!profiles) return;
    const rows = convertUserProfilesToRows(project, profiles);
    setLocalData(rows);
  }, [project, profiles]);


  const [sorting, setSorting] = React.useState<SortingState>([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})


  // Get project dropdown menu
  const getRowDropdownMenu = (
    profileToEdit?: UserProfile | null,
  ) => {

    let items: React.ReactNode[] = [];

    if (!profile || !profileToEdit || !workspace || !project) return items;

    if (profile.id === profileToEdit.id) {
      if (project.is_role("admin", profile.id) && (project.roles.admin.length < 2)) {
        items.push(
          <TooltipProvider>
            <Tooltip delayDuration={0}>
              <TooltipTrigger>
                <DropdownMenuItem
                  disabled
                  key="leave"
                  onClick={() => {}}>
                  <LogOutIcon width={16} height={16} className="stroke-muted-foreground"/>&nbsp;&nbsp;<span className="text-muted-foreground">Leave</span>
                </DropdownMenuItem>
              </TooltipTrigger>
              <TooltipContent className="text-destructive">
                WARNING: You are the only admin! Choose another admin before leaving, or delete the project if you're done with it.
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )
      }
      else {
        items.push(<DropdownMenuItem
          key="leave"
          onClick={() => remove_project_user(project.id, profileToEdit.id).then(x => {
            if (x) {
              window.location.replace("/home");
            }
          })}>
          <LogOutIcon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Leave</span>
        </DropdownMenuItem>)
      }
    }
    else if (canEditProject) {
      items.push(<DropdownMenuItem
        key="remove"
        onClick={() => remove_project_user(project.id, profileToEdit.id)}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Remove</span>
      </DropdownMenuItem>)
    }
    return items;
  }


  const columns: ColumnDef<UserProfileRow>[] = [

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="User" />,
      cell: ({ row }) => (
        <div>&nbsp;{row.getValue("name")}</div>
      ),
    },

    {
      accessorKey: "email",
      header: ({ column }) => <TableHeaderCell column={column} text="Email" />,
      cell: ({ row }) => <div>{row.getValue("email")}</div>,
    },

    {
      accessorKey: "role",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            Role&nbsp;<ArrowUpDownIcon className="ml-2 h-4 w-4" />
          </Button>
        )
      },
      cell: ({ row }) => {
        const profileToEdit = profiles?.find(x => x.id === row.original.id);
        if (!profileToEdit || !workspace || !project) return;

        const currentRole = getProjectRole(project, profileToEdit);

        return <ProjectRolesDropdownMenu
          editable={canEditProject}
          selectedRole={currentRole}
          onSelectRole={newRole => {
            if (project && profileToEdit && currentRole !== newRole) {
              if (newRole === ProjectRoles.admin) {
                transact(
                  project.assign_role("admin", profileToEdit.id, { after: (key, id) => [
                    ...workspace.link(key, id),
                  ] }),
                )
              }
              else {
                transact(
                  project.unassign_role("admin", profileToEdit.id),
                )
              }
              if (newRole === ProjectRoles.editor) {
                transact(
                  project.assign_role("editor", profileToEdit.id, { after: (key, id) => [
                    ...workspace.link(key, id),
                  ] }),
                )
              }
              else {
                transact(
                  project.unassign_role("editor", profileToEdit.id),
                )
              }
            }
          }}

        />
      },
    },

    {
      accessorKey: "more",
      header: ({ column: _ }) => <></>,
      cell: ({ row }) => {
        const profileId = row.original.id;
        const profileToEdit = profiles?.find(x => x.id === profileId);

        const actionButton = <Button
          onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          variant="outline"
          size="icon"
          className={"relative inset-0 opacity-50 group-hover:opacity-100 w-[20px] h-[20px]"}>
          <MoreHorizontalIcon />
        </Button>

        const dropdownMenuContent = canEditProject && profileToEdit && getRowDropdownMenu(profileToEdit);

        if (dropdownMenuContent && dropdownMenuContent.length > 0) {
          return <DropdownMenu>
            <DropdownMenuTrigger asChild>{actionButton}</DropdownMenuTrigger>
            <DropdownMenuContent>{dropdownMenuContent}</DropdownMenuContent>
          </DropdownMenu>
        }

        return undefined;

      },
    }

  ];


  const table = useReactTable({
    data: localData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })


  return <EditableTable
    table={table}
    getUuid={(row) => row.original.id}
    containerClassName={cn(className, "flex-1 min-h-0 h-full")}
    cellClassName="px-2 py-4 align-middle"
  />

}
