"use client";

import React from "react";
import { ChevronDownIcon, MoreHorizontalIcon, PencilIcon, TrashIcon, UserIcon, UserPlusIcon } from "lucide-react";
import { 
  TimelineRoot, 
  TimelineHeader,
  TimelineBody,
  TimelineSidebar,
  TimelineContent,
  TimelineTitle, 
  TimelineLane,
  cn,
  TimelineDateRange,
  TimelineCorner,
  Button,
  CreatePhaseModal,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent,
  UserPopover,
  RenamePhaseModal,
  DeletePhaseModal,
} from "@palette.tools/react";
import { subDays, differenceInDays, max, min } from 'date-fns';
import { Group, Item, useDogs, IterateChild, IterateGroup } from "@palette.tools/react.dogs";
import { Asset, getPermissions, Phase, Project, Task, UserProfile, Workspace } from "@palette.tools/model.client";
import { getProfileName } from "@palette.tools/model";
import { Avatar } from "../../image";

type TaskRow = {
  rowType: 'task',
  id: string;
  title: string;
  duration: number;
  end: Date;
  asset: Asset;
}

type ProfileRow = {
  rowType: 'profile',
  id: string;
  title: string;
  duration: number;
  end: Date;
  image_url?: string;
}

type PhaseRow = {
  rowType: 'phase',
  id: string;
  title: string;
  duration: number;
  end: Date;
}

type UnassignedRow = {
  rowType: 'unassigned',
  id: string;
  title: string;
  duration: number;
  end: Date;
}

type PhaseTimelineRow = PhaseRow | ProfileRow | TaskRow | UnassignedRow;

const constructTimelineTree = (
  phases: Phase[],
  profilesByPhase: Record<string, UserProfile[]>,
  tasksByPhase: Record<string, Task[]>,
  tasksByProfile: Record<string, Task[]>,
  profilesByTask: Record<string, UserProfile[]>,
): (Item<PhaseTimelineRow> | Group<PhaseTimelineRow>)[] => {

  console.log("constructTimelineTree", { phases, profilesByPhase, tasksByPhase, tasksByProfile, profilesByTask });

  return phases
    .sort((a, b) => {
      const aEnd = new Date(a.data.deadline || Date.now());
      const bEnd = new Date(b.data.deadline || Date.now());
      if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
      
      const aStart = new Date(aEnd.getTime() - (a.data.estimate || 0));
      const bStart = new Date(bEnd.getTime() - (b.data.estimate || 0));
      if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
      
      if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
      
      return a.id.localeCompare(b.id);
    })
    .map(phase => ({
      __id: phase.id,
      data: {
        rowType: 'phase' as const,
        id: phase.id,
        title: phase.data.name || "",
        duration: phase.data.estimate || 0,
        end: new Date(phase.data.deadline || Date.now()),
      },
      __children: [
        ...(profilesByPhase[phase.id] || [])
          .sort((a, b) => {
            const aName = getProfileName(a);
            const bName = getProfileName(b);
            if (aName !== bName) return aName.localeCompare(bName);
            return a.id.localeCompare(b.id);
          })
          .map(profile => {
            const profileTasks = (tasksByProfile[profile.id] || []).filter(task => profilesByTask[task.id]?.[0]?.id === profile.id); // Only the first assignee
            const phaseTasks = profileTasks.filter(task => tasksByPhase[phase.id]?.some(x => x.id === task.id));

            const { profileStart, profileEnd } = phaseTasks.reduce(
              ({ profileStart, profileEnd }, task) => {
                if (!task.data.deadline || !task.data.estimate) return { profileStart, profileEnd };
                const start = new Date(task.data.deadline - task.data.estimate);
                const end = new Date(task.data.deadline);
                return {
                  profileStart: !profileStart ? start : min([profileStart, start]),
                  profileEnd: !profileEnd ? end : max([profileEnd, end]),
                };
              },
              { profileStart: null, profileEnd: null } as { profileStart: Date | null, profileEnd: null | Date }
            );

            return {
              __id: `${phase.id}-${profile.id}`,
              data: {
                rowType: 'profile' as const,
                id: profile.id,
                title: getProfileName(profile),
                duration: profileStart && profileEnd ? profileEnd.getTime() - profileStart.getTime() : 0,
                end: profileEnd || new Date(),
                image_url: profile.data.image_url,
              },
              __children: phaseTasks
                .sort((a, b) => {
                  const aEnd = new Date(a.data.deadline || Date.now());
                  const bEnd = new Date(b.data.deadline || Date.now());
                  if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
                  
                  const aStart = new Date(aEnd.getTime() - (a.data.estimate || 0));
                  const bStart = new Date(bEnd.getTime() - (b.data.estimate || 0));
                  if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
                  
                  if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
                  
                  return a.id.localeCompare(b.id);
                })
                .map(task => ({
                  __id: task.id,
                  data: {
                    rowType: 'task' as const,
                    id: task.id,
                    title: task.data.name || "",
                    duration: task.data.estimate || 0,
                    end: new Date(task.data.deadline || Date.now()),
                  },
                })),
            };
          }),
        {
          __id: `${phase.id}-unassigned`,
          data: {
            rowType: 'unassigned' as const,
            id: `${phase.id}-unassigned`,
            title: "Unassigned",
            duration: 0,
            end: new Date(),
          },
          __children: (tasksByPhase[phase.id] || [])
            .filter(task => !profilesByTask[task.id]?.map(Boolean).includes(true))
            .sort((a, b) => {
              const aEnd = new Date(a.data.deadline || Date.now());
              const bEnd = new Date(b.data.deadline || Date.now());
              if (aEnd.getTime() !== bEnd.getTime()) return aEnd.getTime() - bEnd.getTime();
              
              const aStart = new Date(aEnd.getTime() - (a.data.estimate || 0));
              const bStart = new Date(bEnd.getTime() - (b.data.estimate || 0));
              if (aStart.getTime() !== bStart.getTime()) return aStart.getTime() - bStart.getTime();
              
              if (a.data.name && b.data.name && a.data.name !== b.data.name) return a.data.name.localeCompare(b.data.name);
              
              return a.id.localeCompare(b.id);
            })
            .map(task => ({
              __id: task.id,
              data: {
                rowType: 'task' as const,
                id: task.id,
                title: task.data.name || "",
                duration: task.data.estimate || 0,
                end: new Date(task.data.deadline || Date.now()),
              },
            })),
        },
      ],
    }));
};

function AddUserButton({
  users,
  disabled,
  onAddUser,
}: {
  users?: UserProfile[],
  disabled?: boolean,
  onAddUser?: (user: UserProfile) => Promise<void>,
}) {

  return <div
    className={cn(
      "flex flex-col content-center items-center float-left",
      disabled ? "cursor-not-allowed" : "cursor-pointer",
    )}
    onClick={(e) => {
      if (disabled) return;
      e.preventDefault(); e.stopPropagation();
    }}
    dogs-selection-enabled="false"
    dogs-dragging-enabled="false"
  >
    <UserPopover
      modal
      disable={disabled}
      selectableUsers={users}
      onSelectUser={async (user) => {
        await onAddUser?.(user);
      }}
    >
      <div className="w-[30px] h-[30px] flex flex-row items-center place-content-center">
        <UserPlusIcon className="w-[15px] h-[15px]" />
      </div>
    </UserPopover>
  </div>

}

export const PhaseTimeline: React.FC<{
  className?: string,
  externalDataKey?: string,
  profiles: UserProfile[],
  workspace: Workspace | null,
  project: Project | null,
  tasks: Task[],
  phases: Phase[],
  profilesByPhase: Record<string, UserProfile[]>,
  profilesByTask: Record<string, UserProfile[]>,
  tasksByPhase: Record<string, Task[]>,
  tasksByProfile: Record<string, Task[]>,
  assetByTask: Record<string, Asset>,
  canCreatePhase?: boolean,
  getCanEditPhase?: (phase?: Phase | null) => boolean,
  getCanDeletePhase?: (phase?: Phase | null) => boolean,
  onAddUserToPhase?: (phase: Phase, user: UserProfile) => Promise<void>,
  onCreatePhase?: (props: Parameters<typeof Phase.create>[0]) => Promise<void>,
  onDeletePhase?: (props: {id: string}) => Promise<void>,
  onDropTask?: (props: {id: string, phaseId: string, profileId?: string, beforeTaskId?: string, afterTaskId?: string}) => Promise<void>,
  onMoveTaskToUser?: (task: Task, phase: Phase, user: UserProfile | null) => Promise<void>,
  onRemoveTaskFromPhase?: (task: Task, phase: Phase) => Promise<void>,
  onRemoveUserFromPhase?: (user: UserProfile, phase: Phase) => Promise<void>,
  onRenamePhase?: (props: {id: string, name: string}) => Promise<void>,
  onUpdatePhase?: (props: {id: string, estimate: number, deadline: number}) => Promise<void>,
  onUpdateTask?: (props: {id: string, estimate: number, deadline: number}) => Promise<void>,
}> = ({
  className,
  profiles,
  workspace,
  project,
  tasks,
  phases,
  profilesByPhase,
  profilesByTask,
  tasksByPhase,
  tasksByProfile,
  assetByTask,
  externalDataKey = "palette-phase-timeline",
  canCreatePhase = true,
  getCanEditPhase = () => true,
  getCanDeletePhase = () => true,
  onAddUserToPhase,
  onCreatePhase,
  onDeletePhase,
  onDropTask,
  onMoveTaskToUser,
  onRemoveTaskFromPhase,
  onRemoveUserFromPhase,
  onRenamePhase,
  onUpdatePhase,
  onUpdateTask,
}) => {

  const phasesById = React.useMemo(() => {
    return phases?.reduce((acc, phase) => {
      acc[phase.id] = phase;
      return acc;
    }, {} as Record<string, Phase>);
  }, [phases]);

  const profilesById = React.useMemo(() => {
    return profiles?.reduce((acc, profile) => {
      acc[profile.id] = profile;
      return acc;
    }, {} as Record<string, UserProfile>);
  }, [profiles]);

  const tasksById = React.useMemo(() => {
    return tasks?.reduce((acc, task) => {
      acc[task.id] = task;
      return acc;
    }, {} as Record<string, Task>);
  }, [tasks]);

  const items = React.useMemo(() => {
    if (!phases || !profilesByPhase || !tasksByPhase || !tasksByProfile) return [];
    return constructTimelineTree(
      phases,
      profilesByPhase,
      tasksByPhase,
      tasksByProfile,
      profilesByTask,
    );
  }, [phases, profilesByPhase, tasksByPhase, tasksByProfile, profilesByTask]);

  const [collapsedGroups, setCollapsedGroups] = React.useState<Set<string>>(new Set());
  const [openMoreOptions, setOpenMoreOptions] = React.useState<string | null>(null);

  function setSelected(ids: string[]) {
    /* TODO: Implement */
  }

  const { iterate } = useDogs<PhaseTimelineRow>({
    items,
    insertItems: (insertedItems, index, parentId) => {
      console.log("insertItems", insertedItems, index, parentId);
    },
    insertExternalItems: (insertedItems, index, parentId) => {
      console.log("insertExternalItems", insertedItems, index, parentId);
    },
    externalDataKey,
    selectionMode: 'single',
  });

  const renderTimelineRow = (row: IterateChild<PhaseTimelineRow> | IterateGroup<PhaseTimelineRow>, inSidebar: boolean, parentRows?: IterateGroup<PhaseTimelineRow>[]) => {

    const isCollapsed = collapsedGroups.has(row.id);

    const toggleCollapse = () => {
      setCollapsedGroups(prev => {
        const newCollapsedGroups = new Set(prev);
        newCollapsedGroups.has(row.id) ? newCollapsedGroups.delete(row.id) : newCollapsedGroups.add(row.id);
        return newCollapsedGroups;
      });
    };

    // Phase
    if (row.itemType === 'group' && row.group.data.rowType === 'phase') {

      const phase = phasesById[row.group.data.id];
      const canEditPhase = getCanEditPhase?.(phase);
      const canDeletePhase = getCanDeletePhase?.(phase);

      const optionMenuItems = [] as React.ReactNode[];

      if (canEditPhase) {
        optionMenuItems.push(
          <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => setPhaseToRename(phase || null)}>
            <PencilIcon width={16} height={16} />
            <span className="text-xs font-medium">Rename</span>
          </DropdownMenuItem>
        );
      }

      if (canDeletePhase) {
        optionMenuItems.push(
          <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => setPhaseToDelete(phase || null)}>
            <TrashIcon width={16} height={16} className="text-destructive" />
            <span className="text-destructive text-xs font-medium">Delete</span>
          </DropdownMenuItem>
        );
      }

      const moreOptions = optionMenuItems.length > 0 ? (
        <DropdownMenu open={openMoreOptions === row.id} onOpenChange={open => setOpenMoreOptions(open ? row.id : null)}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onCloseAutoFocus={e => e.preventDefault()} onClick={e => e.stopPropagation()}>
            {optionMenuItems}
          </DropdownMenuContent>
        </DropdownMenu>
      ) : null;

      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (

            <TimelineTitle
              rowId={row.id}
              ref={row.ref}
              onClick={toggleCollapse}
              className={cn(
                "h-[32px] flex flex-row gap-x-[2px] items-center group/title",
                "text-base font-semibold select-none",
                row.isSelected ? "bg-[#202020]" : "",
              )}
            >
              <ChevronDownIcon
                dogs-selection-enabled="false"
                width={16}
                height={16}
                onClick={e => { e.stopPropagation(); toggleCollapse() }}
                className={cn("transition-transform duration-100", collapsedGroups.has(row.id) ? "-rotate-90" : "")}
              />
              {row.group.data.title}
              <AddUserButton
                disabled={!canEditPhase}
                users={profiles?.filter(x => !profilesByPhase?.[row.group.data.id]?.includes(x))}
                onAddUser={async (user) => {
                  if (!canEditPhase || !phase) return;
                  await onAddUserToPhase?.(phase, user);
                }}
              />
              <div className="flex-1" />
              {moreOptions}
            </TimelineTitle>

          ) : (

            <TimelineLane
              rowId={row.id}
              className={cn(
                "h-[32px]",
                row.isSelected ? "bg-[#202020]" : "",
              )}
            >
              <TimelineDateRange
                ref={row.ref}
                start={new Date(row.group.data.end.getTime() - row.group.data.duration)}
                end={row.group.data.end}
                style={{ opacity: 1 - (0.33 * (row.depth))}}
                className={cn(
                  "h-[22px]",
                  row.isSelected ? "ring-[0.5px] ring-white ring-offset-[2px] ring-offset-black/50" : ""
                )}
                onUpdate={(start, end) => {
                  onUpdatePhase?.({
                    id: row.group.data.id,
                    estimate: end.getTime() - start.getTime(),
                    deadline: end.getTime(),
                  });
                }}
              />
            </TimelineLane>

          )}
          {!isCollapsed
            ? row.iterate().map(child => {
                return child.itemType === 'child' || child.itemType === 'group' ? renderTimelineRow(child, inSidebar, [...(parentRows || []), row]) : null;
              })
            : null
          }
        </React.Fragment>
      );

    // Profile
    } else if (row.itemType === 'group' && row.group.data.rowType === 'profile') {

      const parentRow = parentRows?.[parentRows.length - 1];
      const parentPhase = parentRow ? phasesById?.[parentRow.group.data.id] : null;
      const profile = profilesById?.[row.group.data.id] || null;
      const canEditParentPhase = getCanEditPhase?.(parentPhase);

      const moreOptions = canEditParentPhase ?
        <DropdownMenu open={openMoreOptions === row.id} onOpenChange={open => setOpenMoreOptions(open ? row.id : null)}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onCloseAutoFocus={e => e.preventDefault()} onClick={e => e.stopPropagation()}>
            <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => {
              if (!profile || !parentPhase) return;
              onRemoveUserFromPhase?.(profile, parentPhase);
            }}>
              <TrashIcon width={16} height={16} className="text-destructive" />
              <span className="text-destructive text-xs font-medium">Remove</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> : null;

      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (

            <TimelineTitle
              rowId={row.id}
              className={cn(
                "h-[32px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",
                "text-medium font-semibold select-none",
              )}
              onClick={toggleCollapse}
            >
              <ChevronDownIcon
                width={16}
                height={16}
                className={cn("transition-transform duration-100", collapsedGroups.has(row.id) ? "-rotate-90" : "")}
              />
              <Avatar 
                src={row.group.data.image_url}
                size={20}
                className="rounded-full"
              />
              {row.group.data.title} 
              <div className="flex-1" />
              {moreOptions}
            </TimelineTitle>
        
          ) : (

            <TimelineLane
              rowId={row.id}
              className="h-[32px]"
            >
              <TimelineDateRange
                start={new Date(row.group.data.end.getTime() - row.group.data.duration)}
                end={row.group.data.end}
                className="h-[18px] bg-[#868688]"
              />
            </TimelineLane>

          )}
          {!isCollapsed
            ? row.iterate().map(child => {
                return child.itemType === 'child' || child.itemType === 'group' ? renderTimelineRow(child, inSidebar, [...(parentRows || []), row]) : null;
              })
            : null
          }
        </React.Fragment>
      );

    // Task
    } else if (row.itemType === 'child' && row.item.data.rowType === 'task') {

      const task = tasksById?.[row.item.data.id] || null;
      const parentPhaseRow = parentRows ? parentRows.toReversed().find(x => x.group.data.rowType === 'phase') : null;
      const parentPhase = parentPhaseRow ? phasesById?.[parentPhaseRow.group.data.id] : null;

      const moreOptions =
        <DropdownMenu open={openMoreOptions === row.id} onOpenChange={open => setOpenMoreOptions(open ? row.id : null)}>
          <DropdownMenuTrigger onClick={e => e.stopPropagation()}>
            <MoreHorizontalIcon width={16} height={16} className="group-hover/title:opacity-100 opacity-0" />
          </DropdownMenuTrigger>
          <DropdownMenuContent onCloseAutoFocus={e => e.preventDefault()} onClick={e => e.stopPropagation()}>
            <DropdownMenuSub>
              <DropdownMenuSubTrigger>
                <span className="text-xs font-medium">Move To</span>
              </DropdownMenuSubTrigger>
              <DropdownMenuSubContent>
                {phases?.map(phase => (
                  <DropdownMenuSub>
                    <DropdownMenuSubTrigger className="text-xs font-medium">{phase.data.name}</DropdownMenuSubTrigger>
                    <DropdownMenuSubContent>

                      {profilesByPhase?.[phase.id]?.map(profile => (
                        <DropdownMenuItem
                          key={profile.id}
                          className="flex flex-row gap-x-2 text-xs font-medium"
                          onClick={() => {
                            if (!task || !profile || !getCanEditPhase?.(phase)) return;
                            onMoveTaskToUser?.(task, phase, profile);
                          }}
                        >
                          <Avatar 
                            src={profile.data.image_url}
                            size={15}
                            className="rounded-full"
                          />
                          <span>{getProfileName(profile)}</span>
                        </DropdownMenuItem>
                      ))}

                      {<DropdownMenuItem
                        key="unassigned"
                        className="flex flex-row gap-x-2 text-xs font-medium"
                        onClick={() => {
                          if (!task) return;
                          onMoveTaskToUser?.(task, phase, null);
                        }}
                      >
                        <Avatar 
                          src={""}
                          size={15}
                          className="rounded-full"
                        />
                        <span>Unassigned</span>
                      </DropdownMenuItem>}

                    </DropdownMenuSubContent>
                  </DropdownMenuSub>
                ))}
              </DropdownMenuSubContent>
            </DropdownMenuSub>
            <DropdownMenuItem className="items-center flex flex-row gap-x-2" onClick={() => {
              if (!task || !parentPhase) return;
              onRemoveTaskFromPhase?.(task, parentPhase);
            }}>
              <TrashIcon width={16} height={16} className="text-destructive" />
              <span className="text-destructive text-xs font-medium">Remove</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>

      return inSidebar ? (

        <TimelineTitle
          ref={row.ref}
          dogs-dragging-enabled="true"
          dogs-selection-enabled="true"
          key={`title-${row.id}`}
          rowId={row.id}
          className={cn(
            "h-[32px] text-sm pl-[48px] flex items-center group/title",
            "text-xs font-semibold select-none",
            row.isSelected ? "bg-[#202020]" : "",
          )}
        >
          <div className="flex flex-row min-w-[200px] max-w-[200px] overflow-hidden">
            <div className="flex-1 truncate">{row.item.data.title}</div>
            <div className="flex-1 truncate">
              {assetByTask?.[row.item.data.id]?.data.name || ""}
            </div>
          </div>
          {moreOptions}
        </TimelineTitle>

      ) : (

        <TimelineLane
          ref={row.ref}
          dogs-dragging-enabled="false"
          dogs-selection-enabled="false"
          key={`lane-${row.id}`}
          rowId={row.id}
          className={cn(
            "h-[32px]",
            row.isSelected ? "bg-[#202020]" : "",
          )}
        >
          <TimelineDateRange
            dogs-dragging-enabled="true"
            dogs-selection-enabled="true"
            start={new Date(row.item.data.end.getTime() - row.item.data.duration)}
            end={row.item.data.end}
            className={cn("bg-[#4a4a4d]", row.isSelected ? "ring-[0.5px] ring-white ring-offset-[1px] ring-offset-black/50" : "")}
            onUpdate={(start, end) => {
              onUpdateTask?.({
                id: row.item.data.id,
                estimate: end.getTime() - start.getTime(),
                deadline: end.getTime(),
              });
            }}
          />
        </TimelineLane>

      );
    } else if (row.itemType === 'group' && row.group.data.rowType === 'unassigned') {
      return (
        <React.Fragment key={row.id}>
          {inSidebar ? (
            <TimelineTitle
              rowId={row.id}
              className={cn(
                "h-[32px] flex flex-row gap-x-[4px] items-center pl-[24px] group/title",
                "text-medium font-semibold select-none",
              )}
              onClick={toggleCollapse}
            >
              <ChevronDownIcon
                width={16}
                height={16}
                className={cn("transition-transform duration-100", collapsedGroups.has(row.id) ? "-rotate-90" : "")}
              />
              <Avatar 
                src={""}
                size={20}
                className="rounded-full"
              />
              {row.group.data.title}
            </TimelineTitle>
          ) : (
            <TimelineLane
              rowId={row.id}
              className="h-[32px]"
            />
          )}
          {!isCollapsed
            ? row.iterate().map(child => {
                return child.itemType === 'child' || child.itemType === 'group' ? renderTimelineRow(child, inSidebar, [...(parentRows || []), row]) : null;
              })
            : null
          }
        </React.Fragment>
      );
    }
  };

  const [isOpenCreatePhaseModal, setIsOpenCreatePhaseModal] = React.useState(false);
  const [phaseToRename, setPhaseToRename] = React.useState<Phase | null>(null);
  const [phaseToDelete, setPhaseToDelete] = React.useState<Phase | null>(null);
  const modals = <>
    <CreatePhaseModal
      open={isOpenCreatePhaseModal}
      onClose={() => setIsOpenCreatePhaseModal(false)}
      canCreatePhase={canCreatePhase}
      onCreatePhase={onCreatePhase}
    />
    <RenamePhaseModal
      open={!!phaseToRename}
      onClose={() => setPhaseToRename(null)}
      phase={phaseToRename}
      disabled={!getCanEditPhase(phaseToRename)}
      onRename={async newName => phaseToRename ? await onRenamePhase?.({id: phaseToRename.id, name: newName}) : undefined}
    />
    <DeletePhaseModal
      open={!!phaseToDelete}
      onClose={() => setPhaseToDelete(null)}
      disabled={!getCanDeletePhase(phaseToDelete)}
      onDelete={async () => phaseToDelete ? await onDeletePhase?.({id: phaseToDelete.id}) : undefined}
    />
  </>

  return <>
    {modals}
    {iterate().map(item => {
      if (item.itemType === 'root') return (
        <TimelineRoot key={`root-${item.id}`} className={className}>
          <TimelineHeader>
            <TimelineCorner className="h-full flex flex-row items-center justify-end p-2">
              {canCreatePhase && <Button variant="cta" onClick={() => setIsOpenCreatePhaseModal(true)} size="sm">New</Button>}
            </TimelineCorner>
          </TimelineHeader>
          <TimelineBody>
              <TimelineSidebar className="min-w-[200px]">
              {item.iterate().map(item => item.itemType === 'child' || item.itemType === 'group' ? renderTimelineRow(item, true) : null)}
              </TimelineSidebar>
              <TimelineContent>
              {item.iterate().map(item => item.itemType === 'child' || item.itemType === 'group' ? renderTimelineRow(item, false) : null)}
              </TimelineContent>
          </TimelineBody>
        </TimelineRoot>
      );
    })}
  </>;
};