"use client";

import React, { useEffect, useMemo } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { ArrowUpDown, ChevronRightIcon } from "lucide-react";

import {
    Button, Tooltip, TooltipProvider, TooltipTrigger,
} from "@palette.tools/react";

import { TaskStatus } from "@palette.tools/model";
import { TooltipContent } from "@radix-ui/react-tooltip";
import { Avatar } from "../image";
import EditableTable from "./EditableTable";
import { Task, UserProfile } from "@palette.tools/model.client";
import { getProfileName } from "@palette.tools/model/src/Profile";


interface UserProfileRow {
  thumbnail_url: string,
  profileId: string,
  name: string,
  remaining: number,
  needsReview: number,
}


function convertUserProfilesToRows(profiles?: UserProfile[], tasks?: Task[]): UserProfileRow[] {
  let rows: UserProfileRow[] = [];
  if (!profiles || !tasks) {
    return rows;
  }
  for (let profile of profiles) {
    const assigned = tasks.filter(task => task.is_role("assignee", profile.id));
    const remaining = assigned.filter(task => task.data.status !== TaskStatus.Complete && task.data.status !== TaskStatus.NeedsReview).length;
    const needsReview = assigned.filter(task => task.data.status === TaskStatus.NeedsReview).length;
    rows.push({
      thumbnail_url: profile.data.image_url || "",
      profileId: profile.id,
      name: getProfileName(profile),
      remaining: remaining,
      needsReview: needsReview,
    });
  }
  return rows;
}


export const UserTasksOverviewTable: React.FC<{
  users: UserProfile[],
  tasks: Task[],
  onClickUser?: (user: UserProfile) => void,
}> = ({
  users,
  tasks,
  onClickUser
}) => {

const columns: ColumnDef<UserProfileRow>[] = useMemo(() => [
    {
      accessorKey: "name",
      header: ({ column }) =>
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          User&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>,
      cell: ({ row }) => (
        <div className="flex flex-row items-center gap-x-2"><Avatar user={row.index < users.length ? users[row.index] : undefined} />&nbsp;{row.getValue("name")}</div>
      ),
    },

    {
      accessorKey: "remaining",
      header: ({ column }) =>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
              Tasks Remaining&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>Tasks "Not Started", "In Progress" or "Needs Work"</TooltipContent>
        </Tooltip>,
      cell: ({ row }) => <div className="w-full flex flex-row">
        <div className="flex-1" />
          {row.getValue("remaining") === 0 ? " " : row.getValue("remaining")}
        <div className="flex-[2]" />
      </div>,
    },

    {
      accessorKey: "needsReview",
      header: ({ column }) =>
        <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
          Needs Review&nbsp;<ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>,
      cell: ({ row }) => <div className="w-full flex flex-row">
        <div className="flex-1" />
          {row.getValue("needsReview") === 0 ? " " : row.getValue("needsReview")}
        <div className="flex-[2]" />
      </div>,
    },

    {
      accessorKey: "action",
      header: () => <></>,
      cell: ({ row }) => <ChevronRightIcon className="h-4 w-4" />,
    }
  ], [users, tasks]);

  // Convert user profiles to row model.
  const [localData, setLocalData] = React.useState<UserProfileRow[]>(convertUserProfilesToRows(users, tasks));
  useEffect(() => {
    setLocalData(convertUserProfilesToRows(users, tasks));
  }, [users, tasks]);

  const [sorting, setSorting] = React.useState<SortingState>([{ id: 'remaining', desc: true }, { id: 'needsReview', desc: true }, { id: "name", desc: true } ])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [columnVisibility, setColumnVisibility] =
    React.useState<VisibilityState>({})
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable({
    data: localData,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })


  return <TooltipProvider>
    <EditableTable
      containerClassName="max-w-[920px] min-w-[770px] flex-1 min-h-0 h-full"
      table={table}
      getUuid={(row) => row.original.profileId}
      cellClassName="py-4 px-2 align-middle"
      onClickRow={(row) => {
        if (!onClickUser) return;
        const user = users.find((user) => user.id === row.original.profileId);
        user && onClickUser(user);
      }}
    />
  </TooltipProvider>

}
