"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Phase } from "@palette.tools/model.client";


export const RenamePhaseModal: React.FC<{
  open: boolean,
  onClose: () => void,
  phase: Phase | null,
  disabled?: boolean,
  onRename?: (name: string) => Promise<void>,
}> = ({
  open,
  onClose,
  phase,
  disabled,
  onRename,
}) => {

  // Regular hooks
  const [name, setName] = useState(phase?.data.name || "");
  const [isCreating, setUpdating] = useState(false);

  useEffect(() => {
    if (phase && phase.data.name) {
      setName(phase.data.name);
    }
  }, [phase]);

  let content: React.ReactNode;
  if (phase) {
    if (disabled) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to rename this phase</TypographyMedium>
    }
    else {
      content = <div className="flex flex-row w-full items-start place-content-start py-3">
        <div className="flex flex-row gap-x-4 items-center">
          <div className="flex flex-col gap-y-2">
            <Input
              className="w-[300px]"
              autoFocus
              defaultValue={name}
              name="name"
              type="text"
              placeholder="Name of phase..."
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
      </div>
    }
  }
  else if (open) {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }
  else {
    content = <div className="w-[300px] h-[65px]" />
  }

  const submitDisabled = (
    !name.trim()
    || name === phase?.data.name
    || isCreating
    || !phase
    || disabled
    || !onRename
  );

  return <Modal
    open={open}
    header={"Rename Phase"}
    description={""}
    primaryButtonContent={isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Updating"}</> : "Update"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setUpdating(true);
        onRename(name).finally(() => {
          setUpdating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
