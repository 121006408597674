export interface CommentLike {
  data: {
    created_at?: number | undefined;
    created_by?: string | undefined;
    frame?: number | null | undefined;
  }
}

interface MessageGroupOptions {
  sortByFrame?: boolean;
  interval: number | null;
}

export function getMessageGroupsFromComments<C extends CommentLike>(
  comments?: C[],
  options: MessageGroupOptions = {
    interval: 300000,
  }
): C[][] {
  if (!comments || comments.length < 1) {
    return [];
  }

  const sortedComments: C[] = [...(comments || [])].sort((a, b) => {
    if (options.sortByFrame) {
      const frameA = a.data.frame && a.data.frame > 0 ? a.data.frame : 0;
      const frameB = b.data.frame && b.data.frame > 0 ? b.data.frame : 0;

      if (frameA !== frameB) {
        return frameA - frameB;
      }
    }

    return (a.data.created_at || 0) - (b.data.created_at || 0);
  });

  if (options.interval === null) {
    return sortedComments.map(comment => [comment]);
  }

  let commentGroups: C[][] = [];
  let currentEntry: C[] = sortedComments[0] ? [sortedComments[0]] : [];

  for (let i = 1; i < sortedComments.length; i++) {
    const curr = sortedComments[i];
    const prev = sortedComments[i - 1];

    if (
      curr.data.created_by !== prev.data.created_by ||
      ((curr.data.created_at || 0) - (prev.data.created_at || 0)) > options.interval
    ) {
      commentGroups.push(currentEntry);
      currentEntry = [];
    }

    currentEntry.push(curr);
  }

  commentGroups.push(currentEntry);

  return commentGroups;
}
