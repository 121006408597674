import { useCallback } from 'react';

export type CallbackWithDeps<T extends (...args: any[]) => any> =
  | T
  | [T, any[]];

export function useCallbackWithDeps<T extends (...args: any[]) => any>(
  callbackOrTuple: CallbackWithDeps<T> | undefined,
  defaultCallback: T
): T {
  return useCallback(
    typeof callbackOrTuple === 'undefined'
      ? defaultCallback
      : typeof callbackOrTuple === 'function'
      ? callbackOrTuple
      : callbackOrTuple[0],
    typeof callbackOrTuple === 'function' || typeof callbackOrTuple === 'undefined'
      ? []
      : callbackOrTuple[1]
  );
}