import React, { useRef, useState, useEffect, useCallback, createContext, useContext } from 'react';

interface TooltipPosition {
  top: number;
  left: number;
}

type Orientation = 'top' | 'bottom' | 'left' | 'right';

interface TooltipAnchorOptions {
  gap: number;
  allowedOrientations?: Orientation[];
  overrideShow?: boolean;
}

interface AnchorData {
  updatePosition: (event: MouseEvent) => void;
  isHovered: boolean;
}

interface AnchoredTooltipContextType {
  registerAnchor: (anchor: HTMLElement, updatePosition: (event: MouseEvent) => void) => void;
  unregisterAnchor: (anchor: HTMLElement) => void;
  setAnchorHovered: (anchor: HTMLElement, isHovered: boolean) => void;
}

const AnchoredTooltipContext = createContext<AnchoredTooltipContextType | null>(null);

export function AnchoredTooltipProvider({ children }: { children: React.ReactNode }) {
  const anchors = useRef<Map<HTMLElement, AnchorData>>(new Map());

  const handleMouseMove = useCallback((event: MouseEvent) => {
    anchors.current.forEach((data) => {
      data.updatePosition(event);
    });
  }, []);

  const registerAnchor = useCallback((anchor: HTMLElement, updatePosition: (event: MouseEvent) => void) => {
    anchors.current.set(anchor, { updatePosition, isHovered: false });
  }, []);

  const unregisterAnchor = useCallback((anchor: HTMLElement) => {
    anchors.current.delete(anchor);
  }, []);

  const setAnchorHovered = useCallback((anchor: HTMLElement, isHovered: boolean) => {
    const data = anchors.current.get(anchor);
    if (data) {
      data.isHovered = isHovered;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [handleMouseMove]);

  return (
    <AnchoredTooltipContext.Provider value={{ registerAnchor, unregisterAnchor, setAnchorHovered }}>
      {children}
    </AnchoredTooltipContext.Provider>
  );
}

export function useTooltipAnchor<T extends HTMLElement, U extends HTMLElement>(
  options: TooltipAnchorOptions
) {
  const { gap = 10, allowedOrientations = ['top', 'bottom', 'left', 'right'] } = options;
  const anchorRef = useRef<T | null>(null);
  const tooltipRef = useRef<U | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<TooltipPosition | null>(null);
  const [isHovered, setIsHovered] = useState(false);

  const context = useContext(AnchoredTooltipContext);

  const updateTooltipPosition = useCallback((event: MouseEvent) => {
    if (!anchorRef.current || !tooltipRef.current) return;

    const anchorRect = anchorRef.current.getBoundingClientRect();

    if (isMouseWithinElement(event, anchorRect) || options.overrideShow) {
      setIsHovered(true);
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      const distToTop = event.clientY - anchorRect.top;
      const distToBottom = anchorRect.bottom - event.clientY;
      const distToLeft = event.clientX - anchorRect.left;
      const distToRight = anchorRect.right - event.clientX;

      const allowedDistances = allowedOrientations.map(orientation => {
        switch (orientation) {
          case 'top': return distToTop;
          case 'bottom': return distToBottom;
          case 'left': return distToLeft;
          case 'right': return distToRight;
        }
      });
      const minDist = Math.min(...allowedDistances);
      const closestOrientation = allowedOrientations[allowedDistances.indexOf(minDist)];

      let left, top;

      switch (closestOrientation) {
        case 'top':
          left = Math.max(anchorRect.left, Math.min(event.clientX - tooltipRect.width / 2, anchorRect.right - tooltipRect.width));
          top = anchorRect.top - tooltipRect.height - gap;
          break;
        case 'bottom':
          left = Math.max(anchorRect.left, Math.min(event.clientX - tooltipRect.width / 2, anchorRect.right - tooltipRect.width));
          top = anchorRect.bottom + gap;
          break;
        case 'left':
          left = anchorRect.left - tooltipRect.width - gap;
          top = Math.max(anchorRect.top, Math.min(event.clientY - tooltipRect.height / 2, anchorRect.bottom - tooltipRect.height));
          break;
        case 'right':
          left = anchorRect.right + gap;
          top = Math.max(anchorRect.top, Math.min(event.clientY - tooltipRect.height / 2, anchorRect.bottom - tooltipRect.height));
          break;
      }

      left = Math.max(0, Math.min(left, window.innerWidth - tooltipRect.width));
      top = Math.max(0, Math.min(top, window.innerHeight - tooltipRect.height));

      setTooltipPosition({ left, top });
    } else {
      setIsHovered(false);
      setTooltipPosition(null);
    }
  }, [gap, allowedOrientations, options.overrideShow]);

  const isMouseWithinElement = (event: MouseEvent, rect: DOMRect) => {
    return (
      event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom
    );
  };

  useEffect(() => {
    const anchor = anchorRef.current;
    if (!anchor) return;

    if (context) {
      context.registerAnchor(anchor, updateTooltipPosition);

      return () => {
        context.unregisterAnchor(anchor);
      };
    } else {
      anchor.addEventListener('mousemove', updateTooltipPosition);
      anchor.addEventListener('mouseleave', () => setTooltipPosition(null));

      return () => {
        anchor.removeEventListener('mousemove', updateTooltipPosition);
        anchor.removeEventListener('mouseleave', () => setTooltipPosition(null));
      };
    }
  }, [updateTooltipPosition, context]);

  useEffect(() => {
    if (tooltipRef.current) {
      if (tooltipPosition && (isHovered || options.overrideShow)) {
        tooltipRef.current.style.position = 'fixed';
        tooltipRef.current.style.left = `${tooltipPosition.left}px`;
        tooltipRef.current.style.top = `${tooltipPosition.top}px`;
      } else {
        tooltipRef.current.style.position = 'fixed';
        tooltipRef.current.style.left = '-9999px';
        tooltipRef.current.style.top = '-9999px';
      }
    }
  }, [tooltipPosition, isHovered, options.overrideShow]);

  return { anchorRef, tooltipRef };
}

export default useTooltipAnchor;