

export const DEFAULT_DRAG_THRESHOLD = 5;
export const DEFAULT_GROUP_DEAD_ZONE: ZoneLength = { length: "10px", max: "20%" };

export interface Item<T> {
  __id: string;
  data: T,
}

export interface Group<T> extends Item<T> {
  __children: Item<T>[] | Group<T>[];
}

export function isGroup(item: any): item is Group<any> {
  return !!item && (item as Group<any>).__id !== undefined && (item as Group<any>).__children !== undefined;
}

export type Pixels = `${number}px`;
export type Percent = `${number}%`;
export type ZoneLength =
  Pixels // Fixed length in pixels, no constraints
  | Percent // Variable length in percent, no constraints
  | { length: Percent, min?: Pixels, max?: Pixels } // Variable length, but cannot be smaller than a fixed length or larger than a fixed length
  | { length: Pixels, min?: Percent, max?: Percent } // Fixed length, but cannot be smaller than a percentage of the total or larger than a percentage of the total

const isPixels = (dimension: Pixels | Percent | ZoneLength): dimension is Pixels => typeof dimension !== "object" && dimension.endsWith('px') && !!parseInt(dimension.slice(0, -2), 10);
const isPercent = (dimension: Pixels | Percent | ZoneLength): dimension is Percent => typeof dimension !== "object" && dimension.endsWith('%') && !!parseInt(dimension.slice(0, -1), 10);
const pixelsToNumber = (pixels: Pixels) => parseInt(pixels.slice(0, -2), 10);
const percentToNumber = (percent: Percent) => parseInt(percent.slice(0, -1), 10) / 100;

export function isLengthWithinZone({ length, zone, total }: { length: Pixels | Percent, zone: ZoneLength, total: Pixels }) {

  const totalPixels = pixelsToNumber(total) || 1;

  const lengthPixels = isPixels(length) ? pixelsToNumber(length) : percentToNumber(length) * pixelsToNumber(total);

  if (isPixels(zone)) {
    return lengthPixels <= pixelsToNumber(zone);
  }
  else if (isPercent(zone)) {
    return lengthPixels <= percentToNumber(zone) * totalPixels;
  }
  else {
    const min = zone.min ? isPixels(zone.min) ? pixelsToNumber(zone.min) : percentToNumber(zone.min) * totalPixels : 0;
    const max = zone.max ? isPixels(zone.max) ? pixelsToNumber(zone.max) : percentToNumber(zone.max) * totalPixels : Number.POSITIVE_INFINITY;
    const zoneLength = isPixels(zone.length) ? pixelsToNumber(zone.length) : percentToNumber(zone.length) * totalPixels;
    const clampedZoneLength = Math.max(Math.min(Math.max(zoneLength, min), max), 1);
    return lengthPixels <= clampedZoneLength;
  }

}
