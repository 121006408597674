"use client";

import React from "react";
import { ChevronDownIcon } from "lucide-react";
import { 
  TimelineRoot, 
  TimelineHeader,
  TimelineBody,
  TimelineSidebar,
  TimelineContent,
  TimelineTitle, 
  TimelineLane,
  cn,
  TimelineDateRange
} from "@palette.tools/react";
import { addDays, addMonths, subDays, differenceInDays, isValid, max, min } from 'date-fns';
import { Group, Item, useDogs, IterateChild, IterateGroup } from "@palette.tools/react.dogs";

const today = new Date();

type TimelineRow = {
  id: string;
  title: string;
  duration: number;
  end: Date;
};

const createGroup = (id: string, title: string, children: (Item<TimelineRow> | Group<TimelineRow>)[]): Group<TimelineRow> => {
  const groupEnd = children.reduce((maxEnd, child) => {
    if (!('data' in child)) return maxEnd;
    return child.data.end > maxEnd ? child.data.end : maxEnd;
  }, new Date(-8640000000000000)); // Min date

  const groupStart = children.reduce((minStart, child) => {
    if (!('data' in child)) return minStart;
    const childStart = subDays(child.data.end, child.data.duration);
    return childStart < minStart ? childStart : minStart;
  }, new Date(8640000000000000)); // Max date

  const groupDuration = differenceInDays(groupEnd || today, groupStart || today);

  console.log({ groupEnd, groupStart, groupDuration });

  return {
    __id: id,
    __children: children,
    data: {
      id,
      title,
      duration: groupDuration || 0,
      end: groupEnd || today,
    }
  };
};

const sortLeafItems = (items: (Item<TimelineRow> | Group<TimelineRow>)[]): (Item<TimelineRow> | Group<TimelineRow>)[] => {
  return items.map(item => {
    if ('__children' in item) {
      return {
        ...item,
        __children: sortLeafItems(item.__children)
      };
    }
    return item;
  });
};

const sortItemsWithinGroups = (items: (Item<TimelineRow> | Group<TimelineRow>)[]): (Item<TimelineRow> | Group<TimelineRow>)[] => {
  return items.map(item => {
    if ('__children' in item) {
      return {
        ...item,
        __children: sortItemsWithinGroups(item.__children).sort((a, b) => {
          const aEnd = 'data' in a ? a.data.end : new Date();
          const bEnd = 'data' in b ? b.data.end : new Date();
          if (aEnd.getTime() !== bEnd.getTime()) {
            return aEnd.getTime() - bEnd.getTime();
          }
          const aDuration = 'data' in a ? a.data.duration : 0;
          const bDuration = 'data' in b ? b.data.duration : 0;
          return bDuration - aDuration;
        })
      };
    }
    return item;
  });
};

const generateSampleItems = (today: Date = new Date(), start: Date, end: Date): (Item<TimelineRow> | Group<TimelineRow>)[] => {
  const createItem = (id: string, title: string, duration: number, end: Date): Item<TimelineRow> => ({
    __id: id,
    data: {
      id,
      title,
      duration,
      end,
    }
  });

  const unsortedItems = [
    createGroup('group1', 'Group 1', [
      createItem('project-alpha', 'Project Alpha', 60, addDays(today, 30)),
      createItem('task-beta', 'Task Beta', 30, addDays(today, 15)),
      createItem('initiative-gamma', 'Initiative Gamma', 60, addDays(today, 0)),
    ]),
    createGroup('group2', 'Group 2', []),
    createGroup('group3', 'Group 3', [
      createItem('testing-zeta', 'Testing Zeta', 30, addDays(today, 45)),
    ]),
    createGroup('group4', 'Group 4', [
      createItem('research-delta', 'Research Delta', 90, addDays(today, 45)),
      createItem('development-epsilon', 'Development Epsilon', 60, addDays(today, 60)),
    ]),
    createGroup('group5', 'Group 5', [
      createItem('deployment-eta', 'Deployment Eta', 15, addDays(today, 45)),
      createItem('marketing-theta', 'Marketing Theta', 60, addDays(today, 30)),
      createItem('customer-support-iota', 'Customer Support Iota', 180, addDays(today, 90)),
      createItem('frontend-nu', 'Frontend Nu', 60, addDays(today, 70)),
      createItem('database-migration-xi', 'Database Migration Xi', 30, addDays(today, 25)),
    ]),
    createGroup('group6', 'Group 6', [
      createGroup('subgroup1', 'Subgroup 1', [
        createItem('product-design-kappa', 'Product Design Kappa', 30, addDays(today, 10)),
        createItem('ui-ux-lambda', 'UI/UX Lambda', 30, addDays(today, 20)),
      ]),
      createGroup('subgroup2', 'Subgroup 2', [
        createItem('backend-mu', 'Backend Mu', 60, addDays(today, 65)),
        createItem('security-audit-omicron', 'Security Audit Omicron', 15, addDays(today, 35)),
        createItem('performance-optimization-pi', 'Performance Optimization Pi', 30, addDays(today, 55)),
      ]),
      createItem('client-meeting-rho', 'Client Meeting Rho', 1, addDays(today, -1)),
      createItem('team-building-sigma', 'Team Building Sigma', 1, addDays(today, 6)),
      createItem('conference-tau', 'Conference Tau', 3, addDays(today, 43)),
    ]),
    createGroup('group7', 'Group 7', [
      createGroup('subgroup3', 'Subgroup 3', [
        createItem('holiday-break-upsilon', 'Holiday Break Upsilon', 5, addDays(today, 90)),
        createItem('budget-review-phi', 'Budget Review Phi', 2, addDays(today, -23)),
        createItem('hiring-process-chi', 'Hiring Process Chi', 30, addDays(today, -10)),
        createItem('training-program-psi', 'Training Program Psi', 30, addDays(today, 80)),
        createItem('software-update-omega', 'Software Update Omega', 15, addDays(today, 75)),
      ]),
    ]),
  ];

  return sortItemsWithinGroups(sortLeafItems(unsortedItems));
};

// Modify the updateItems function to include sorting within groups
const updateItems = (updatedRows: TimelineRow[], items: (Item<TimelineRow> | Group<TimelineRow>)[]): (Item<TimelineRow> | Group<TimelineRow>)[] => {
  const updateItem = (item: Item<TimelineRow> | Group<TimelineRow>): Item<TimelineRow> | Group<TimelineRow> => {
    if ('__children' in item) {
      const updatedChildren = item.__children.map(updateItem);
      
      const validChildren = updatedChildren.filter(child => 
        'data' in child && isValid(child.data.end) && isValid(subDays(child.data.end, child.data.duration))
      );

      if (validChildren.length === 0) {
        // If no valid children, return the item unchanged
        return { ...item, __children: updatedChildren };
      }

      const groupEnd = max(validChildren.map(child => child.data.end));
      const groupStart = min(validChildren.map(child => subDays(child.data.end, child.data.duration)));

      const groupDuration = differenceInDays(groupEnd, groupStart);

      return {
        ...item,
        __children: updatedChildren,
        data: {
          ...item.data,
          duration: Math.max(0, groupDuration), // Ensure non-negative duration
          end: groupEnd,
        },
      };
    } else {
      const updatedData = updatedRows.find(updated => updated.id === item.data.id);
      if (updatedData) {
        const newEnd = isValid(updatedData.end) ? updatedData.end : item.data.end;
        const newDuration = isValid(updatedData.duration) ? updatedData.duration : item.data.duration;
        return {
          ...item,
          data: {
            ...item.data,
            ...updatedData,
            end: newEnd,
            duration: Math.max(0, newDuration), // Ensure non-negative duration
          }
        };
      }
      return item;
    }
  };

  return items.map(updateItem);
};

export const DemoTimeline: React.FC<{
}> = () => {
  const [items, setItems] = React.useState<(Item<TimelineRow> | Group<TimelineRow>)[]>(() => 
    sortItemsWithinGroups(generateSampleItems(today, addMonths(today, -1), addMonths(today, 1)))
  );

  const handleUpdateRows = React.useCallback((updatedRows: TimelineRow[]) => {
    setItems(prevItems => {
      const newItems = updateItems(updatedRows, prevItems);
      return sortItemsWithinGroups(newItems);
    });
  }, []);

  const [collapsedGroups, setCollapsedGroups] = React.useState<Set<string>>(new Set());
  // Iteratively flatten the items

  const { iterate } = useDogs<TimelineRow>({
    items,
    setItems,
    selectionMode: 'single',
  });

  const renderTimelineItem = (item: IterateChild<TimelineRow> | IterateGroup<TimelineRow>, inSidebar: boolean) => {
    if (item.itemType === 'group') {
      const isCollapsed = collapsedGroups.has(item.id);
      // It's a group

      return (
        <React.Fragment key={item.id}>
          {inSidebar ? (
            <TimelineTitle
              rowId={item.id}
              className="text-sm select-none flex flex-row gap-x-[2px] items-center"
              style={{ paddingLeft: `${16 + item.depth * 14}px` }}
              
            >
              <ChevronDownIcon
                width={16}
                height={16}
                className={cn("transition-transform duration-100", collapsedGroups.has(item.id) ? "-rotate-90" : "")}
                onClick={() => {
                  setCollapsedGroups(prev => {
                    const newCollapsedGroups = new Set(prev);
                    newCollapsedGroups.has(item.id) ? newCollapsedGroups.delete(item.id) : newCollapsedGroups.add(item.id);
                    return newCollapsedGroups;
                  });
                }}
              />
              {item.group.data.title}
            </TimelineTitle>
          ) : (
            <TimelineLane rowId={item.id}>
              <TimelineDateRange
                start={subDays(item.group.data.end, item.group.data.duration)}
                end={item.group.data.end}
                style={{ opacity: 1 - (0.33 * (item.depth))}}
              />
            </TimelineLane>
          )}
          {!isCollapsed
            ? item.iterate().map(child => child.itemType === 'child' || child.itemType === 'group' ? renderTimelineItem(child, inSidebar) : null)
            : null
          }
        </React.Fragment>
      );
    } else {

      // It's an item
      return inSidebar ? (
        <TimelineTitle
          ref={item.ref}
          key={`title-${item.id}`}
          rowId={item.id}
          className={cn("text-sm select-none", item.isSelected ? "bg-[#303030] data-[row-hover=true]:bg-[#303030]" : "")}
          style={{ paddingLeft: `${16 + (item.depth * 14) + (item.depth > 0 ? 2 : 0)}px` }}
        >
          {item.item.data.title}
        </TimelineTitle>
      ) : (
        <TimelineLane
          key={`lane-${item.id}`}
          rowId={item.id}
          className={cn(
            item.isSelected ? "bg-[#303030] data-[row-hover=true]:bg-[#303030]" : "",
          )}
        >
          <TimelineDateRange
            ref={item.ref}
            start={subDays(item.item.data.end, item.item.data.duration)}
            end={item.item.data.end}
            className={cn(item.isSelected ? "ring-2 ring-gray-200" : "")}
            style={{ backgroundColor: `rgba(255, 255, 255, ${1 - (0.33 * (item.depth))})` }}
            onUpdate={(start, end) => {
              handleUpdateRows([{
                id: item.item.data.id,
                title: item.item.data.title,
                end,
                duration: differenceInDays(end, start),
              }]);
            }}
          />
        </TimelineLane>
      );
    }
  };


  return iterate().map(item => {
    if (item.itemType === 'root') return (
      <TimelineRoot key={`root-${item.id}`} className="flex-1">
        <TimelineHeader />
        <TimelineBody>
            <TimelineSidebar>
            {item.iterate().map(item => item.itemType === 'group'
                ? renderTimelineItem(item, true)
                : null
            )}
            </TimelineSidebar>
            <TimelineContent>
            {item.iterate().map(item => item.itemType === 'child' || item.itemType === 'group' ? renderTimelineItem(item, false) : null)}
            </TimelineContent>
        </TimelineBody>
      </TimelineRoot>
    );
  })
};
