"use client";

import React, { useEffect, useState } from "react";
import { Modal } from "./Modal";
import { LoadingIcon } from "@palette.tools/react.icons";
import { TypographyMedium } from "../typography";
import { Input } from "../shadcn/components/ui/input";
import { Label } from "../shadcn/components/ui/label";
import { Phase, useAuth, id as uuid } from "@palette.tools/model.client";
import { DateRange } from "react-day-picker";
import { DatePickerWithRange } from "@/components/blocks/date-picker-with-range";


export const CreatePhaseModal: React.FC<{
  open: boolean,
  onClose: () => void,
  onCreatePhase?: (props: Parameters<typeof Phase.create>[0]) => Promise<void>,
  canCreatePhase?: boolean,
}> = ({
  open,
  onClose,
  onCreatePhase,
  canCreatePhase,
}) => {

  const { profile, isLoading: isLoadingProfile } = useAuth();

  // Regular hooks
  const [name, setName] = useState("");
  const [estimate, setEstimate] = useState<number | null>(null);
  const [deadline, setDeadline] = useState<number | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const [isCreating, setCreating] = useState(false);

  useEffect(() => {
    if (dateRange?.to && dateRange?.from) {
      setDeadline(dateRange.to.getTime());
      setEstimate(Math.ceil((dateRange.to.getTime() - dateRange.from.getTime())));
    }
  }, [dateRange]);

  let content: React.ReactNode;
  if (isLoadingProfile) {
    content = <div className="w-[24px] h-[24px]"><LoadingIcon /></div>;
  }
  else if (profile) {
    if (!canCreatePhase) {
      content = <TypographyMedium className="italic">Sorry, you are not allowed to create phases here</TypographyMedium>
    }
    else {
      content = <div className="flex flex-col gap-2">
        <Label htmlFor="name">Name</Label>
        <Input
          autoFocus
          name="name"
          type="text"
          placeholder="Name of your phase..."
          onChange={(e) => setName(e.target.value)}
        />
        <DatePickerWithRange
          onSelectDateRange={setDateRange}
          dateRange={dateRange}
        />
      </div>
    }
  }
  else {
    content = <TypographyMedium className="text-destructive">An error occurred!</TypographyMedium>
  }

  const submitDisabled = (
    !name.trim()
    || isLoadingProfile
    || !canCreatePhase
    || !onCreatePhase
    || estimate == null
    || deadline == null
  );

  return <Modal
    open={open}
    header={"Create Phase"}
    description={"Create a new phase for your project"}
    primaryButtonContent={isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" Creating"}</> : "Create"}
    secondaryButtonContent="Cancel"
    primaryButtonDisabled={submitDisabled}
    onClose={() => {
      setName("");
      setEstimate(null);
      setDeadline(null);
      setDateRange(undefined);
      onClose();
    }}
    onSubmit={async () => {
      if (!submitDisabled) {
        setCreating(true);
        const id = uuid();
        onCreatePhase({
          id,
          name,
          deadline,
          estimate,
        }).finally(() => {
          setCreating(false);
          onClose();
        });
      }
    }}
  >
    {content}
    <div className="min-h-[10px]" />
  </Modal>

}
