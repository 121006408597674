"use client";

import React from "react";
import { TaskState } from "firebase/storage";
import { FileEntry, Project, Workspace, uploadDrawover } from "@palette.tools/model.client";
import { ProgressModal } from "../modals";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";


export const useDrawoverUpload = (
  workspace: Workspace | null,
  project: Project | null,
  file_entry: FileEntry | null,
) => {
  const canUpload = !!workspace && !!project && !!file_entry;
  const [progress, setProgress] = React.useState<number>(0);
  const [error, setError] = React.useState<string>("");
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [drawoverId, setDrawoverId] = React.useState<string | null>(null);

  const upload = async (
    frame: number,
    data: object,
    is_empty?: boolean,
    callbacks?: {
      onCreate?: (drawoverId: string) => void
      onStart?: () => void
      onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void
      onFinish?: (drawoverId: string | null) => void
    }
  ) => {
    if (!canUpload) return;

    flushSync(() => {
      setError("");
      setIsUploading(true);
    })
    callbacks?.onStart?.();
    setProgress(0.05);
    await uploadDrawover(
      workspace,
      project,
      file_entry,
      frame,
      JSON.stringify(data),
      is_empty,
      (_fileId) => {
        callbacks?.onCreate?.(_fileId);
        setDrawoverId(_fileId);
      },
      (state, bytesTransferred, totalBytes) => {
        const adjusted_progress = ((bytesTransferred / totalBytes) * 0.90) + 0.05;
        setProgress(adjusted_progress);
        callbacks?.onProgress?.(state, bytesTransferred, totalBytes);
      }
    )
    .catch((e) => setError(e.message))
    .finally(() => {
      setIsUploading(false);
      callbacks?.onFinish?.(drawoverId);
    });
  };

  return {
    canUpload,
    progress,
    error,
    isUploading,
    drawoverId,
    upload,
  };
};


interface DrawoverUploadTriggerProps<T extends React.ElementType> {
  workspace: Workspace;
  project: Project;
  file_entry: FileEntry;
  frame: number;
  data: object;
  is_empty?: boolean | undefined;
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  disabled?: boolean;
  onCreate?: (drawoverId: string) => void;
  onStart?: () => void;
  onProgress?: (state: TaskState, bytesTransferred: number, totalBytes: number) => void;
  onFinish?: (drawoverId: string | null) => void;
}

export const DrawoverUploadTrigger = <T extends React.ElementType>({
  workspace,
  project,
  file_entry,
  frame,
  data,
  is_empty,
  children,
  disabled,
  onCreate,
  onStart,
  onProgress,
  onFinish,
}: DrawoverUploadTriggerProps<T>) => {

  const { isUploading, error, progress, upload } = useDrawoverUpload(workspace, project, file_entry);

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      if (disabled) return;
      upload(frame, data, is_empty, { onCreate, onStart, onProgress, onFinish });
    }
  }
  const clone = React.cloneElement(children, props);

  const trigger = disabled
    ? <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{clone}</TooltipTrigger>
        <TooltipContent className="italic text-muted-foreground">Workspace not set up for uploading.</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    : clone;

  return <>
    {isUploading ? <ProgressModal open={isUploading} error={error} progress={progress}>Uploading...</ProgressModal> : null}
    {trigger}
  </>

}
