"use client";

import React, { useRef, useState } from "react"
import { Popover, PopoverContent, PopoverTrigger } from "../shadcn/components/ui/popover";


import {
  DateValue,
  useButton,
  useDatePicker,
  useInteractOutside,
} from "react-aria";
import { DatePickerStateOptions, useDatePickerState } from "react-stately";
import { useForwardedRef } from "../shadcn/lib/useForwardedRef";
import { PickerCalendar } from "../date-time-picker/calendar";
import { TimeField } from "../date-time-picker/time-field";
import { parseAbsolute, getLocalTimeZone } from "@internationalized/date";
import { Button } from "../shadcn/components/ui/button";


const DateTimePicker = React.forwardRef<
  HTMLDivElement,
  DatePickerStateOptions<DateValue> & {
    children: React.ReactNode,
    showClear?: boolean,
  }
>((props, forwardedRef) => {

  const ref = useForwardedRef(forwardedRef);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);

  const state = useDatePickerState(props);
  const {
    buttonProps: _buttonProps,
    dialogProps,
    calendarProps,
  } = useDatePicker(props, state, ref);

  useInteractOutside({
    ref: contentRef,
    onInteractOutside: (e) => {
      setOpen(false);
    },
  });

  return (
    <Popover open={open} onOpenChange={setOpen} modal>
      <PopoverTrigger className="cursor-pointer">
        {props.children}
      </PopoverTrigger>
      <PopoverContent ref={contentRef} className="w-full">
        <div {...dialogProps} className="space-y-3">
          <PickerCalendar {...calendarProps} />
          {!!state.hasTime && (
            <TimeField
              value={state.timeValue}
              onChange={state.setTimeValue}
            />
          )}
          {props.showClear && (
            <div className="w-full flex flex-row">
              <Button variant="destructive" onClick={() => { state.setValue(null) }}>Clear</Button>
              <div className="flex-1" />
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
});


export const DatetimePopover: React.FC<{
  disable?: boolean,
  children: React.ReactNode,
  selectedDatetime?: Date,
  onSelectDatetime?: (datetime: Date | null) => void,
  showClear?: boolean,
  granularity?: "minute" | "hour" | "day",
}> = ({
  granularity = "minute",
  disable = false,
  children,
  selectedDatetime,
  onSelectDatetime,
  showClear = true,
}) => {

  const value = !!selectedDatetime ? parseAbsolute(selectedDatetime.toISOString(), getLocalTimeZone()) : null;

  if (disable) {
    return children;
  }

  return <DateTimePicker
    value={value}
    granularity={granularity}
    shouldCloseOnSelect
    onChange={(date) => {
      const newDatetime = !!date ? date.toDate(getLocalTimeZone()) : null;
      onSelectDatetime && onSelectDatetime(newDatetime);
    }}
    showClear={showClear}
  >{children}</DateTimePicker>

}