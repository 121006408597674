"use client";

import React, { useEffect, useMemo, useRef, useState } from "react";

import {
    ColumnDef,
    ColumnFiltersState,
    Row,
    RowSelectionState,
    SortingState,
    VisibilityState,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable,
} from "@tanstack/react-table";

import { GripVerticalIcon, MoreHorizontalIcon, Trash2Icon, UserIcon, UserPlusIcon } from "lucide-react";

import {
    Button, ContextMenuItem, DatetimePopover, DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger, ElementType, Input, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger, UserPopover
} from "@palette.tools/react";

import { TaskStatus } from "@palette.tools/model";
import { LoadingIcon } from "@palette.tools/react.icons";
import { filterUndefined } from "@palette.tools/utils";
import { Avatar } from "../image";
import { DeadlineLabel } from "../labels";
import { StatusDropdownMenu } from "../menus";
import { cn } from "../shadcn/lib/utils";
import EditableTable from "./EditableTable";
import { TableHeaderCell } from "./shared";
import { Asset, Category, Project, Task, UserProfile, Workspace, getPermissions, transact, useAuth, usePermissions } from "@palette.tools/model.client";
import { EventWrappedTransactionChunk } from "@palette.tools/model.core";


interface TaskRow {
  id: string,
  name: string,
  assignee: string | undefined,
  deadline: number | undefined,
  status: TaskStatus,
}

function convertTaskToRow(task: Task): TaskRow {

  return {
    id: task.id,
    name: task.data.name || "",
    assignee: task.roles.assignee[0] || undefined,
    deadline: task.data.deadline,
    status: task.data.status as TaskStatus,
  }
}


function convertRowToTask(tasks: Task[] | undefined, row: Row<TaskRow>) {
  return (tasks || []).find(x => x.id === row.getValue("id"));
}


const NameCell: React.FC<{
  task?: Task,
  editable?: boolean,
}> = ({
  task,
  editable,
}) => {

  const displayNameInputRef = useRef<HTMLInputElement>(null);
  const [displayName, setDisplayName] = useState<string | null>(task?.data.name || null);

  async function commitDisplayName(newName: string) {
    const nameToSet = newName.trim();
    if (task && nameToSet && nameToSet !== task.data.name) {
      await transact(task.update({ name: nameToSet }))
    }
    if (displayNameInputRef.current) {
      displayNameInputRef.current.blur();
    }
  }

  return <div className="min-w-[150px] max-w-[300px]">
    {editable ? <Input
      dogs-selection-enabled="false"
      dogs-dragging-enabled="false"
      ref={displayNameInputRef}
      name="display-name"
      className="max-w-[300px]"
      defaultValue={task?.data.name}
      value={displayName === null ? task?.data.name : displayName}
      draggable
      onClick={(e) => {e.preventDefault(); e.stopPropagation()}}
      onDrag={(e) => {e.preventDefault(); e.stopPropagation()}}
      onDragStart={(e) => {e.preventDefault(); e.stopPropagation()}}
      onDragEnter={(e) => {e.preventDefault(); e.stopPropagation()}}
      onBlur={(e) => {
        setDisplayName(e.currentTarget.value);
        commitDisplayName(e.currentTarget.value).then(() => setDisplayName(null));
      }}
      onChange={(e) => setDisplayName(e.target.value)}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          setDisplayName(e.currentTarget.value)
          commitDisplayName(e.currentTarget.value).then(() => setDisplayName(null));
        }
    }}
    /> : <p
      className="max-w-full line-clamp-2 break-all"
    >{displayName}</p>}
  </div>
}


function getAssigneeCell(workspace: Workspace | null, project: Project | null, users?: UserProfile[], task?: Task, editable?: boolean) {

  const selectedUser = users?.find(x => task?.is_role("assignee", x.id));

  return <div className="flex flex-col content-center items-center float-left ml-4">
    <div
      onClick={(e) => {
        if (!editable) return;
        e.preventDefault(); e.stopPropagation()
      }}
      dogs-selection-enabled="false"
      dogs-dragging-enabled="false"
    >
      <UserPopover
        disable={!editable}
        selectableUsers={users}
        selectedUser={selectedUser}
        onSelectUser={async (user) => {
          if (!workspace || !project || !task) return;
          const assignments = task.links.role__task__assignee || [];
          await transact(
            ...assignments.map(x => {
              const assigneeId = x.links.profile?.[0]?.id;
              if (assigneeId && assigneeId !== user.id) {
                return [...task.unassign_role("assignee", assigneeId), x.update({ trashed: true }) ];
              }
              return [];
            }),
            ...task.assign_role("assignee", user.id, { after: (key, id) => [
              ...project.link(key, id),
              ...workspace.link(key, id),
            ] }),
          )
        }}
        onClearSelection={async () => {
          const assigned = task?.roles.assignee || [];
          task && await transact(assigned.map(x => task.unassign_role("assignee", x)))
        }}
      >
        <div className="w-[40px] h-[40px] flex flex-row items-center place-content-center">
          <TooltipProvider>
            <Tooltip delayDuration={300}>
              <TooltipTrigger>
                {selectedUser
                  ? <Avatar user={selectedUser} size={40} />
                  : editable
                    ? <UserPlusIcon className="w-[20px] h-[20px]" />
                    : <UserIcon className="w-[20px] h-[20px]" />
                }
              </TooltipTrigger>
              <TooltipContent>
                {selectedUser ? selectedUser?.data.email : "No assignee"}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </UserPopover>
    </div>
  </div>

}


const DeadlineCell: React.FC<{
  task?: Task
  editable?: boolean,
}> = ({
  task,
  editable,
}) => {

  return <div className="flex flex-col content-center items-center float-left ml-4">
    <div
      onClick={(e) => {
        if (!editable) return;
        e.preventDefault(); e.stopPropagation();
      }}
      dogs-selection-enabled="false"
      dogs-dragging-enabled="false"
    >
      <DatetimePopover
        disable={!editable}
        selectedDatetime={task?.data.deadline ? new Date(task.data.deadline) : undefined}
        onSelectDatetime={async (datetime) => {
          if (task && editable) {
            await transact(task.update({ deadline: datetime?.getTime() }))
          }
        }}
      >
        <DeadlineLabel
          timestamp={task?.data.deadline}
          editable={editable}
          isComplete={task?.data.status === TaskStatus.Complete}
        />
      </DatetimePopover>
    </div>
  </div>
}


const StatusCell: React.FC<{
  task?: Task,
  editable?: boolean,
}> = ({
  task,
  editable,
}) => {

  return <div className="flex flex-col content-center items-center float-left">
    <div
      onClick={(e) => {
        if (!editable) return;
        e.preventDefault(); e.stopPropagation();
      }}
      dogs-selection-enabled="false"
      dogs-dragging-enabled="false"
    >
      <StatusDropdownMenu
        editable={editable}
        selectedStatus={task?.data.status}
        onSelectStatus={async (status) => {
          task && await transact(task.update({ status }));
        }}
      />
    </div>
  </div>
}


export const TaskTable: React.FC<{
  workspace: Workspace | null,
  project: Project | null,
  category: Category | null,
  asset: Asset | null,
  tasks: Task[],
  isCreating?: boolean,
  initialSelectedTasks?: Task[],
  onSelectTasks?: (selectedTasks: Task[]) => void,
  onClickCreateTask?: (selectedTasks: Task[]) => void,
  onClickDeleteTasks?: (selectedTasks: Task[], selectedTask: Task) => void,
  className?: string,
}> = ({
  workspace,
  project,
  category,
  asset,
  tasks,
  isCreating = false,
  initialSelectedTasks = [],
  onSelectTasks,
  onClickCreateTask,
  onClickDeleteTasks,
  className,
}) => {

  const { profile } = useAuth();
  const { canEditAsset, canCreateTask } = usePermissions({ workspace, project, category, asset });

  const serverData = (asset?.lists.task || []).map(convertTaskToRow);
  const localData = React.useMemo(() => serverData, [JSON.stringify(asset?.serialize())]);
  const setLocalData: React.Dispatch<React.SetStateAction<TaskRow[]>> = (value) => {
    if (typeof value === "function") value = value(localData);
    if (!workspace || !asset) return;
    transact(asset.reorder_items("task", value.map(x => x.id), { after: (key, id) => [
      ...workspace.link(key, id),
    ] }));
  }

  // Table states
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const initialRowSelection = Object.fromEntries(initialSelectedTasks.map(x => [tasks?.findIndex(y => y.id === x.id), true]));
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(initialRowSelection);

  useEffect(() => {
    setRowSelection(initialRowSelection);
  }, [JSON.stringify(initialRowSelection)]);

  // Get project dropdown menu
  const getRowDropdownMenu = (
    task: Task,
  ) => {

    const { canDeleteTask } = getPermissions({ profile, workspace, project, category, asset, task });

    let items: React.ReactNode[] = [];

    if (canDeleteTask) {
      items.push(<DropdownMenuItem
        key="delete"
        onClick={(e) => {
          const selectedTasks: Task[] = table
            .getSelectedRowModel()
            .rows
            .map(x => tasks ? tasks.find(y => y.id === x.original.id): undefined)
            .filter(x => !!x)
            .filter(x => getPermissions({ profile, workspace, project, category, asset, task: x }).canDeleteTask) as Task[];

          e.preventDefault();
          e.stopPropagation();
          onClickDeleteTasks && onClickDeleteTasks(selectedTasks, task);
        }}>
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </DropdownMenuItem>)
    }
    if (items.length > 0) return <DropdownMenuContent>{items}</DropdownMenuContent>;
  }

  // Get project context menu
  const getRowContextMenuItems = (
    row: Row<TaskRow>,
  ) => {

    const task: Task | undefined = tasks ? tasks.find(x => x.id === row.getValue("id")) : undefined;
    let items: ElementType<typeof ContextMenuItem>[] = [];
    if (!task) return items;

    const { canDeleteTask } = getPermissions({ profile, workspace, project, category, asset, task });

    if (canDeleteTask) {
      items.push(<ContextMenuItem
        key="delete"
        onClick={(e) => {
          const selectedTasks: Task[] = filterUndefined(table
            .getSelectedRowModel()
            .rows
            .map(x => tasks ? tasks.find(y => y.id === x.original.id): undefined))
            .filter(x => getPermissions({ profile, workspace, project, category, asset, task: x }).canDeleteTask) as Task[];

          e.preventDefault();
          e.stopPropagation();
          onClickDeleteTasks && onClickDeleteTasks(selectedTasks, task);
        }}
      >
        <Trash2Icon width={16} height={16} className="stroke-destructive"/>&nbsp;&nbsp;<span className="text-destructive">Delete</span>
      </ContextMenuItem>)
    }
    return items;
  }

  const columns: ColumnDef<TaskRow>[] = [

    {
      accessorKey: "id",
      header: () => { return <></> },
      cell: () => canEditAsset ? <div className="opacity-0 group-hover:opacity-100"><GripVerticalIcon /></div> : undefined,
    },

    {
      accessorKey: "name",
      header: ({ column }) => <TableHeaderCell column={column} text="Task"/>,
      cell: ({ row }) => {
        const { canEditTask } = getPermissions({ profile, workspace, project, category, asset, task: convertRowToTask(tasks, row) });
        return <NameCell task={convertRowToTask(tasks, row)} editable={canEditTask} />
      },
    },

    {
      accessorKey: "assignee",
      header: ({ column }) => <TableHeaderCell column={column} text="Assignee"/>,
      enableSorting: false,
      cell: ({ row }) => {
        const { canEditTask } = getPermissions({ profile, workspace, project, category, asset, task: convertRowToTask(tasks, row) })
        return getAssigneeCell(workspace, project, project?.links.profile || [], convertRowToTask(tasks, row), canEditTask);
      },
    },

    {
      accessorKey: "deadline",
      header: ({ column }) => <TableHeaderCell column={column} text="Deadline"/>,
      sortingFn: "datetime",
      cell: ({ row }) => {
        const { canEditTask } = getPermissions({ profile, workspace, project, category, asset, task: convertRowToTask(tasks, row) })
        return <DeadlineCell task={convertRowToTask(tasks, row)} editable={canEditTask} />
      },
    },

    {
      accessorKey: "status",
      header: ({ column }) => <TableHeaderCell column={column} text="Status"/>,
      cell: ({ row }) => {
        const { canEditTask } = getPermissions({ profile, workspace, project, category, asset, task: convertRowToTask(tasks, row) })
        return <StatusCell task={convertRowToTask(tasks, row)} editable={canEditTask} />
      }
    },

    {
      accessorKey: "more",
      header: () => <></>,
      cell: ({ row }) => {

        const task = convertRowToTask(tasks, row)

        const actionButton = <Button
          onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          dogs-selection-enabled="false"
          dogs-dragging-enabled="false"
          variant="outline"
          size="icon"
          className={"relative inset-0 opacity-50 group-hover:opacity-100 w-[20px] h-[20px]"}>
          <MoreHorizontalIcon />
        </Button>

        const dropdownMenuContent = task ? getRowDropdownMenu(task) : undefined;

        const content = <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {actionButton}
          </DropdownMenuTrigger>
          {dropdownMenuContent}
        </DropdownMenu>

        return content;

      },
    },
  ];

  useEffect(() => {
    const selectedTasks: Task[] = table
      .getSelectedRowModel()
      .rows
      .map(x => tasks ? tasks.find(y => y.id === x.original.id): undefined)
      .filter(x => !!x) as Task[];
    onSelectTasks && onSelectTasks(selectedTasks);
  }, [rowSelection]);

  const table = useReactTable({
    data: localData,
    columns,
    enableSortingRemoval: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  return <div className={cn("flex flex-col min-h-0 h-full gap-y-2", className)}>
    <div className="flex flex-row gap-x-2">
      <div className="flex-1"/>
      {canCreateTask ? <Button onClick={() => {
        const selectedTasks: Task[] = table
          .getSelectedRowModel()
          .rows
          .map(x => tasks ? tasks.find(y => y.id === x.original.id): undefined)
          .filter(x => !!x) as Task[];
        onClickCreateTask && onClickCreateTask(selectedTasks);
      }}>{isCreating ? <><div className="w-[16px] h-[16px]"><LoadingIcon /></div>{" New"}</> : "New"}</Button> : undefined}
    </div>

    <EditableTable
      containerClassName="flex-1 min-h-0 h-full"
      rowClassName="group h-min"
      cellClassName="px-2 py-4 align-middle"
      enableOrdering={canEditAsset && sorting.length < 1}
      selectionMode={canEditAsset ? "multi" : "single"}
      table={table}
      getUuid={(row) => row.original.id}
      insertItems={(insertedRows, index, parentRow) => {

        if (!workspace || !project || !asset) return;

        const insertedKeys = insertedRows.map(x => x.original.id);
        const filteredData = localData.filter(x => !insertedKeys.includes(x.id));
        const newOrder = [...filteredData.slice(0, index), ...insertedRows.map(x => x.original), ...filteredData.slice(index)].map(x => x.id);

        const txs: EventWrappedTransactionChunk[] = [];

        if (JSON.stringify(newOrder) !== JSON.stringify(localData.map(x => x.id))) {
          txs.push(
            ...asset.reorder_items("task", newOrder, {
              after: (key, id) => [
                ...workspace.link(key, id),
                ...project.link(key, id),
              ]
            })
          );
        }
        
        transact(txs);

      }}
      getRowContextMenuItems={getRowContextMenuItems}
    />

  </div>

}
