"use client";

import React from "react";
import { Project, Workspace, usePermissions } from "@palette.tools/model.client";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../shadcn/components/ui/tooltip";
import { flushSync } from "react-dom";
import { WorkspaceInviteModal } from "..";
import { create_invite } from "@palette.tools/api.client";


export const useInviteUsers = ({
  workspace,
  project,
}: {
  workspace: Workspace | null,
  project: Project | null,
}) => {

  const { canManageWorkspaceUsers: canInvite } = usePermissions({ workspace, project });

  const [error, setError] = React.useState<string>("");
  const [isInviting, setIsInviting] = React.useState<boolean>(false);
  const [inviteId, setInviteId] = React.useState<string | null>(null);

  const invite = async (
    email: string,
  ) => {
    if (!workspace || !canInvite) return;

    flushSync(() => {
      setError("");
      setIsInviting(true);
    })
    await create_invite(workspace.id, email, project?.id)
    .catch((e) => setError(e.message))
    .finally(() => {
      setIsInviting(false);
    });
  };

  return {
    canInvite,
    error,
    isInviting,
    invite,
  };
};


interface InviteUserTriggerProps<T extends React.ElementType> {
  children: React.ReactElement<React.ComponentPropsWithoutRef<T>>;
  workspace: Workspace | null;
  project?: Project | null;
  disabled?: boolean;
}

export const InviteUserTrigger = <T extends React.ElementType>({
  children,
  workspace,
  project = null,
  disabled,
}: InviteUserTriggerProps<T>) => {

  const [isOpenWorkspaceInviteModal, setOpenWorkspaceInviteModal] = React.useState<boolean>(false);

  const { canInvite, invite } = useInviteUsers({ workspace, project });

  const props: typeof children.props = {
    ...children.props,
    onClick() {
      if (disabled) return;
      setOpenWorkspaceInviteModal(true);
    }
  }
  const clone = React.cloneElement(children, props);

  const trigger = disabled || !canInvite
    ? <TooltipProvider delayDuration={0}>
      <Tooltip>
        <TooltipTrigger asChild>{clone}</TooltipTrigger>
        <TooltipContent className="italic text-muted-foreground">Can't invite users!</TooltipContent>
      </Tooltip>
    </TooltipProvider>
    : clone;

  return <>
    <WorkspaceInviteModal
      open={isOpenWorkspaceInviteModal}
      onClose={() => setOpenWorkspaceInviteModal(false)}
      onInviteEmail={invite}
    />
    {trigger}
  </>

};
