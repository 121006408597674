"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { mergeRefs } from "./elements";


function keyComboToIndex(combo: string[] | readonly string[]): string {
  return JSON.stringify(Array.from(new Set(combo)).toSorted());
}

function indexToKeyCombo(index: string): readonly string[] {
  try {
    const keys = (JSON.parse(index) as string[]).map((key) => {
      return key;
    });
    return Array.from(new Set(keys)).toSorted();
  } catch (e) {
    return [];
  }
}


type ShortcutFn = (combo: Set<string>) => void;
type RawShortcut = [string[], ShortcutFn];
type Shortcut = [readonly string[], ShortcutFn];
type ShortcutMap = Record<string, ShortcutFn>;


export interface ShortcutManager {
  shortcuts: readonly Shortcut[];
  onKeyDown: NonNullable<HTMLElement['onkeydown']>;
  onKeyUp: NonNullable<HTMLElement['onkeyup']>;
  setDisabled: (disabled: boolean, combos?: Set<string>[]) => void;
  handleKeyUp: (key: string) => boolean;
  handleKeyDown: (key: string) => boolean;
}


function isShortcutManager(props: RawShortcut[] | ShortcutManager): props is ShortcutManager {
  return 'shortcuts' in props && 'register' in props && 'deregister' in props && 'onKeyDown' in props && 'onKeyUp' in props;
}


export function useShortcutFocus(manager: ShortcutManager | undefined, isFocused: boolean) {
  useEffect(() => {
    if (!manager || !isFocused) return;
    window.addEventListener('keydown', manager.onKeyDown);
    //window.addEventListener('keyup', manager.onKeyUp);
    return () => {
      window.removeEventListener('keydown', manager.onKeyDown);
      //window.removeEventListener('keyup', manager.onKeyUp);
    }
  }, [manager, isFocused]);
}


export function useShortcutRef<T extends HTMLElement | null>(manager: ShortcutManager | undefined, ref?: React.RefObject<T> | ((instance: T) => void) | null) {
  const internalRef = useRef<T>(null);

  const [isFocused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);

  useEffect(() => {
    if (!internalRef.current) return;
    const tabIndexBefore = internalRef.current.tabIndex;
    internalRef.current.tabIndex = 0;
    internalRef.current.addEventListener('focus', onFocus);

    // Clean up
    return () => {
      if (!internalRef.current) return;
      internalRef.current.removeEventListener('focus', onFocus);
      internalRef.current.tabIndex = tabIndexBefore;
    };

  }, [internalRef.current])

  useEffect(() => {
    if (!manager || !internalRef.current || !isFocused) return;
    internalRef.current.addEventListener('keydown', manager.onKeyDown);
    //window.addEventListener('keyup', manager.onKeyUp);

    // Clean up
    return () => {
      if (!manager || !internalRef.current) return;
      internalRef.current.removeEventListener('keydown', manager.onKeyDown);
      //window.removeEventListener('keyup', manager.onKeyUp);
    };

  }, [manager, internalRef.current, isFocused]);

  return mergeRefs([ref, internalRef]);
}


export function useShortcuts(props: RawShortcut[] | ShortcutManager): ShortcutManager {

  const isPassthrough = isShortcutManager(props);
  const isDisabledRef = useRef(false);
  const disabledCombosRef = useRef<Set<string>[]>([]);
  const keysPressedRef = useRef(new Set<string>());

  const shortcutMap = useMemo(() => {
    const _shortcutMap: ShortcutMap = {};
    (isPassthrough ? props.shortcuts : props).forEach(([keys, action]) => {
      const index = keyComboToIndex(keys);
      if (_shortcutMap[index]) {
        throw new Error(`Duplicate shortcut: ${indexToKeyCombo(index)}`);
      }
      _shortcutMap[index] = action;
    });
    return _shortcutMap;
  }, [props]);

  const shortcuts: readonly Shortcut[] = useMemo(() => isPassthrough ? props.shortcuts : (
    Object.entries(shortcutMap)
      .map(x => [indexToKeyCombo(x[0]), x[1]]) as Shortcut[]
    ).toSorted((a, b) => JSON.stringify(a[0]).localeCompare(JSON.stringify(b[0]))
  ), [shortcutMap]);

  const handleKeys = useCallback((keys: Set<string>) => {
    if (isPassthrough || (isDisabledRef.current && !disabledCombosRef.current.length)) {
      console.log({ isPassthrough: isPassthrough || undefined, isDisabled: isDisabledRef.current || undefined, disabledCombos: disabledCombosRef.current });
      return false;
    };

    const index = keyComboToIndex(Array.from(keys))
    if (disabledCombosRef.current.some((combo) => {
      return combo.size === keys.size && Array.from(combo).every((key) => keys.has(key));
    })) {
      return false;
    }
    const action = shortcutMap[index];
    if (action) {
      action(new Set(keys));
      return true;
    }
    console.log("no action found", shortcutMap, index);
    return false;
  }, [shortcutMap, isPassthrough, isDisabledRef, disabledCombosRef]);

  const handleKeyDown = useCallback((key: string) => {
    console.log("handleKeyDown", key);
    keysPressedRef.current.add(key);
    return handleKeys(keysPressedRef.current);
  }, [handleKeys, keysPressedRef, disabledCombosRef]);

  const onKeyDown = useCallback((event: KeyboardEvent) => {
    if(handleKeyDown(event.key)) {
      event.preventDefault();
    }
  }, [handleKeyDown]);

  const handleKeyUp = useCallback((_: string) => {
    keysPressedRef.current.clear();
    return true;
  }, [handleKeys, keysPressedRef]);

  const onKeyUp = useCallback((event: KeyboardEvent) => {
    handleKeyUp(event.key)
  }, [handleKeyUp]);

  const setDisabled = useCallback((disabled: boolean, disabledCombos: Set<string>[] = []) => {
    isDisabledRef.current = disabled;
    disabledCombosRef.current = disabled ? disabledCombos : [];
  }, [isDisabledRef, disabledCombosRef]);

  const manager: ShortcutManager = useMemo(() => ({
    shortcuts,
    onKeyDown,
    onKeyUp,
    setDisabled,
    handleKeyUp,
    handleKeyDown,
  }), [
    shortcuts,
    onKeyDown,
    onKeyUp,
    setDisabled,
    handleKeyUp,
    handleKeyDown,
  ]);

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    return () => {
      window.removeEventListener('keyup', onKeyUp);
    }
  }, [onKeyUp]);

  useEffect(() => {
    console.log("shortcutManager changed")
  }, [manager]);

  return manager;

}
