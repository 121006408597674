"use client";

import * as React from "react"

import {
  HeaderGroup,
  Row,
  RowData,
  Table as Table_Tanstack,
  flexRender,
} from "@tanstack/react-table"

import {
  Button,
  CallbackWithDeps,
  ContextMenu,
  ContextMenuContent,
  ContextMenuItem,
  ContextMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ElementType,
  cn,
  useCallbackWithDeps,
} from "@palette.tools/react"

import { useDogs, DeadZone, Item, Group, AllowInsertFn, GroupDeadZoneFn } from "@palette.tools/react.dogs";

import { UNGROUPED_GROUP_ID } from "./getCustomOrderedGroupedRowModel";
import { ChevronDownIcon, ChevronRightIcon, MoreHorizontalIcon } from "lucide-react";

export interface AllowInsertRowsFn<T> {
  (props: {
    insertedRows: Row<T>[],
    index: number,
    parentId: string | null
  }): boolean;
}

export interface GroupRowDeadZoneFn<T> {
  (props: {
    groupRow: Row<T>,
    insertedRows: Row<T>[],
    depth: number,
    orientation: "row" | "column",
  }): DeadZone | undefined;
}

// Base interface without localGroups and setLocalGroups
interface EditableTableProps<T> {
  table: Table_Tanstack<T>,
  getUuid: (row: Row<T>) => string,
  insertItems?: (insertedRows: Row<T>[], index: number, parentRow: Row<T> | null) => void,
  containerClassName?: string,
  tableClassName?: string,
  rowClassName?: string,
  cellClassName?: string,
  enableOrdering?: boolean,
  selectionMode?: "none" | "single" | "multi",
  onClickRow?: (row: Row<T>) => void,
  getRowContextMenuItems?: (row: Row<T>) => ElementType<typeof ContextMenuItem>[],
  getGroupRowDropdownMenuItems?: (row: Row<T>) => ElementType<typeof DropdownMenuItem>[],
  allowInsert?: CallbackWithDeps<AllowInsertRowsFn<T>>,
  groupDeadZone?: CallbackWithDeps<GroupRowDeadZoneFn<T>>,
  onIsDraggingChanged?: (isDragging: boolean) => void,
  dragImageText?: (draggedRows: Row<T>[]) => string,
};

function constructItemTree<T>(
  rows: Row<T>[],
  getUuid: (row: Row<T>) => string,
  rowsById: { [id: string]: Row<T> } = {},
  parentRowById: { [id: string]: Row<T> } = {},
  parentRow: Row<T> | null = null,
  parentId?: string,
): {
  tree: (Item<T> | Group<T>)[],
  rowsById: { [id: string]: Row<T> },
  parentRowById: { [id: string]: Row<T> },
} {

  const tree = rows.map(x => {
    const id = getUuid(x);
    rowsById[id] = x;
    if (parentRow) {
      parentRowById[id] = parentRow;
    }
    if (x.getIsGrouped()) {
      const { tree: childTree, rowsById: childRowsById, parentRowById: childParentRowById } = constructItemTree<T>(x.subRows, getUuid, rowsById, parentRowById, x, id);
      rowsById = { ...rowsById, ...childRowsById };
      parentRowById = { ...parentRowById, ...childParentRowById };
      return {
        __id: id,
        __children: childTree,
        data: x.original,
      }
    }
    return { __id: id, data: x.original }
  });
  return { tree, rowsById, parentRowById };
}

export default function EditableTable<T extends RowData>(props: EditableTableProps<T>) {

  const isOrderingEnabled = !!props.insertItems && props.enableOrdering;

  const rows = props.table.getSortedRowModel().rows;
  const { tree: items, rowsById, parentRowById } = React.useMemo(() => constructItemTree<T>(props.table.getSortedRowModel().rows, props.getUuid), [rows, props.getUuid]);

  const allowInsert = useCallbackWithDeps(props.allowInsert, () => true);
  const groupDeadZone = useCallbackWithDeps(props.groupDeadZone, () => undefined);

  const { render, state } = useDogs({
    items,
    insertItems(items, index, parentId) {
      const groupRow = parentId ? rowsById[parentId] : null;
      return props.insertItems?.(items.map(x => rowsById[x.__id]), index, groupRow);
    },
    selectionMode: props.selectionMode,
    enableOrdering: isOrderingEnabled,
    allowInsert: React.useCallback<AllowInsertFn<T>>(({ insertedItems, index, parentId }) => {
      const sortingState = props.table.getState().sorting;
      if (sortingState.length > 0) {
        console.warn("Can't edit sorted table");
        return false;
      }
      return allowInsert({
        insertedRows: insertedItems.map(x => rowsById[x.__id]),
        index,
        parentId,
      });
    }, [allowInsert, rowsById, props.table]),
    groupDeadZone: React.useCallback<GroupDeadZoneFn<T>>((props) => groupDeadZone({
      groupRow: rowsById[props.group.__id],
      insertedRows: props.insertedItems.map(x => rowsById[x.__id]),
      depth: props.depth,
      orientation: props.orientation,
    }), [groupDeadZone, rowsById]),
    treatChildrenAsParentBody: true,
    onSelectionChanged: (selectedIds) => {
      props.table.setRowSelection(selectionState => {
        const newSelectionState: { [id: string]: boolean } = {};
        selectedIds.forEach(id => {
          const row = rowsById[id];
          if (row) {
            newSelectionState[row.id] = true;
          }
        });
        return newSelectionState;
      });
    },
  });

  React.useEffect(() => {
    props.onIsDraggingChanged?.(state.isDragging);
  }, [state.isDragging]);

  const colSpan = props.table.getHeaderGroups().flatMap(x => x.headers).length;

  return render({
    root({ ref, children, ...state }) {
      return <div
        ref={ref}
        className={cn(
          "flex flex-col gap-y-2 rounded-md border overflow-auto",
          props.containerClassName,
        )}
        dogs-enable-ordering={isOrderingEnabled}
        dogs-enable-selection={props.selectionMode !== "none"}
      >
        <table className={cn(
          props.tableClassName,
          "w-full min-h-0",
          state.isDroppingOnEnd ? "border-b-[1px] border-b-primary" : undefined,
        )}>

          <thead className="bg-background sticky top-0 w-full z-10">
            {props.table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} className={cn(
                "select-none hover:bg-muted/50",
                "relative",
                state.isDroppingOnStart ? "after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-primary" : "after:content-[''] after:absolute after:left-0 after:right-0 after:bottom-0 after:h-[1px] after:bg-border"
              )}>
                {headerGroup.headers.map(header =>
                  <th key={header.id} className="p-1 text-left text-muted-foreground">
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                )}
              </tr>
            ))}
          </thead>

          <tbody className="overflow-y-auto w-full">
            {children}
          </tbody>

        </table>
      </div>
    },

    group({ ref, id, children, group, ...state }) {
      const row = rowsById[id];
      if (!row) return null;

      const isExpanded = row.getIsExpanded();
      const isEmpty = row.subRows.length === 0;
      const isUngrouped = row.id === UNGROUPED_GROUP_ID;

      const dropdownItems = props.getGroupRowDropdownMenuItems ? props.getGroupRowDropdownMenuItems(row) : [];

      const dropdownTrigger = dropdownItems.length > 0 ? <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="mr-4 w-[20px] h-[20px] opacity-0 group-hover:opacity-100"
            variant="outline"
            size="icon"
            onClick={(e) => {e.stopPropagation(); e.preventDefault()}}
          >
            <MoreHorizontalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {dropdownItems}
        </DropdownMenuContent>
      </DropdownMenu> : null;

      const chevron = row.getIsExpanded()
        ? <ChevronDownIcon width={16} height={16} />
        : <ChevronRightIcon width={16} height={16} />;

      return <React.Fragment key={id}>
        <tr
          ref={ref}
          key={`td-${id}`}
          className={cn(
            "cursor-pointer h-fit select-none border-t-[1px]",

            // Clickable
            props.onClickRow ? "cursor-pointer" : undefined,

            // Last
            state.isLast ? "border-b-[0px]" : "border-b-[1px]",

            // Hover
            state.isHovering && !state.isDragging ? "bg-muted/50" : undefined,

            // Drag
            state.isDragging ? "cursor-grabbing" : undefined,
            state.isDraggingThis ? "opacity-50 bg-muted/70" : undefined,

            // Drop
            (
              !isUngrouped // Ignored UNGROUPED
              && (state.isDroppingOnNextStart || state.isDroppingOnEnd) // Highlight when dropping on the end, or neighbor's start
              && (
                !isExpanded // Don't highlight when dropping on expanded group. This will be covered by the item at the end of the group.
                || isEmpty // Highlight when dropping on empty group, even if expanded.
                || state.isDroppingWithin // When dropping within the group, highlight.
              )
              || (!isExpanded && state.isDroppingOnNextSiblingStart) // Highlight when collapsed and next sibling is hovered
            ) ? "border-b-primary" : undefined,
            state.isFirst && state.isDroppingOnStart ? "border-t-primary" : undefined,
            state.isDroppingInside ? "ring-inset ring-2 ring-primary" : undefined,

          )}
          onClick={() => row.toggleExpanded()}
        >
          <td colSpan={colSpan}>
            <div className="w-full flex flex-row items-center group min-h-20">
              {chevron}
              <span className={isUngrouped ? "text-muted-foreground uppercase" : "text-foreground"}>
                {row.groupingValue as string}
              </span>
              <div className="flex-1" />
              {dropdownTrigger}
            </div>
          </td>
        </tr>
        {row.getIsExpanded() ? children : null}
      </React.Fragment>

    },

    child({ ref, id, ...state }) {
      const row = rowsById[id];
      if (!row) return null;

      const groupRow = parentRowById[id];
      const isGrouped = !!groupRow;

      const renderedRow = <tr
        ref={ref}
        key={id}

        className={cn(
          // Default
          props.rowClassName, "select-none border-t-[1px]",

          // Clickable
          props.onClickRow ? "cursor-pointer" : undefined,

          // Last
          state.depth < 1 && state.isLast ? "border-b-[0px]" : "border-b-[1px]",

          // Hover
          state.isHovering && !state.isDragging && !state.isSelected ? "bg-muted/50" : undefined,

          // Selection
          state.isSelected ? "bg-muted/75" : undefined,

          // Drag
          state.isDragging ? "cursor-grabbing" : undefined,
          state.isDraggingThis ? "opacity-50 bg-muted/70" : undefined,

          // Drop
          (
            state.isDroppingOnNextStart
            || state.isDroppingOnEnd
            || (isGrouped && state.isLastSibling && state.isDroppingOnParent && !state.isDroppingOnParentStart && state.isDroppingOnParentEnd)
          ) ? "border-b-primary" : undefined,
          state.isFirst && state.isDroppingOnThis && state.isDroppingOnStart && !state.isDraggingGroup ? "border-t-primary" : undefined,

        )}
        onClick={() => props.onClickRow?.(row)}
      >
        {row.getVisibleCells().map((cell) => (
          <td dogs-id={id} key={cell.id} className={props.cellClassName}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>

      const contextItems = props.getRowContextMenuItems ? props.getRowContextMenuItems(row) : [];

      const wrappedRow = contextItems.length > 0 ? <ContextMenu>
        <ContextMenuTrigger asChild>
          {renderedRow}
        </ContextMenuTrigger>
        <ContextMenuContent>
          {contextItems}
        </ContextMenuContent>
      </ContextMenu> : renderedRow;

      return <React.Fragment key={`row-${id}`}>
        {wrappedRow}
      </React.Fragment>

    },

    dragImage({ ref, draggedItems, ...state }) {
      return <div ref={ref} className="w-fit h-fit pl-4 w-screen max-w-[300px]">
        {props.dragImageText ? props.dragImageText(draggedItems.map(x => rowsById[x.__id])) : `${draggedItems.length} item${draggedItems.length !== 1 ? 's' : ''}`}
      </div>
    },
  });

}